import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { getUserPaymentAccounts } from "app/api/payment_accounts";
import { GetUserPaymentAccountsParams } from "app/api/payment_accounts/types";
import parseError from "app/lib/parseError";

export default function useGetPaymentAccounts(
  params?: GetUserPaymentAccountsParams
) {
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ["payment_accounts", params],
    () => getUserPaymentAccounts(params),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    }
  );

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<any>) as string) : undefined,
    accounts: data?.data,
    refetch,
  };
}
