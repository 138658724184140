import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface Props {
  bold?: boolean;
  big?: boolean;
  medium?: boolean;
}
const SubHeader = styled.h4<Props>`
  font-weight: 600;
  font-size: 1.6rem;

  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `};

  ${(props) =>
    props.medium &&
    css`
      font-size: 1.8rem;
    `};

  ${(props) =>
    props.big &&
    css`
      font-size: 2rem;
    `};
`;

export default SubHeader;
