import { useActiveProfile } from "app/providers/ActiveProfileProvider";
import { useProfile } from "app/providers/ProfileProvider";
import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function SpendContainer(props: PropsWithChildren) {
  const activeProfile = useActiveProfile();
  const profile = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      activeProfile?.profile_type === "business" ||
      profile?.country_code === "KE"
    ) {
      navigate("/");
    }
  }, [activeProfile, profile]);

  return <div>{props.children}</div>;
}
