import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { getPaymentMethods } from "app/api/payment_methods";
import { GetPaymentMethodsParams } from "app/api/payment_methods/types";

const useGetPaymentMethods = (params: GetPaymentMethodsParams) => {
  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ["payment-methods", params],
    () => getPaymentMethods(params)
  );

  return {
    isLoading,
    isFetching,
    methods: data?.data.data ?? [],
    error: error ? (parseError(error as AxiosError<any>) as string) : undefined,
    refetchPaymentMethods: refetch,
  };
};

export default useGetPaymentMethods;
