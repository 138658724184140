import {
  BusinessVerificationSectionDropdownOptionsResponse,
  BusinessVerificationSectionsResponse,
  UpdateBusinessVerificationSectionPayload,
} from "./types";
import { BaseResponse, GenericResponse } from "../types";

import request from "../request";
import { ACTIVE_PROFILE_STORAGE_KEY, AUTH_URL } from "app/constants/variables";
import storage from "app/lib/storage";
import isEmpty from "app/lib/isEmpty";

const defaultOption = { baseURL: AUTH_URL };

const getRequestHeaders = () => {
  const { id } = storage.get(ACTIVE_PROFILE_STORAGE_KEY) ?? {};

  if (isEmpty(id)) return {};

  return {
    "X-BU-PROFILE-ID": id,
  };
};

export const getAllBusinessVerificationSections = () => {
  return request.get<GenericResponse<BusinessVerificationSectionsResponse>>(
    "/identities/business",
    {
      ...defaultOption,
      headers: getRequestHeaders(),
    }
  );
};

export const updateBusinessVerificationSection = (
  section_key: string,
  payload: UpdateBusinessVerificationSectionPayload
) => {
  return request.put<BaseResponse>(
    `/identities/business/${section_key}`,
    payload,
    { ...defaultOption, headers: getRequestHeaders() }
  );
};

export const submitBusinessVerification = () => {
  return request.post<BaseResponse>(
    "/identities/business",
    {},
    { ...defaultOption, headers: getRequestHeaders() }
  );
};

export const getBusinessVerificationSectionDropdownOptions = (
  section_key: string
) => {
  return request.get<
    GenericResponse<BusinessVerificationSectionDropdownOptionsResponse>
  >(`/identities/business/${section_key}/options`, defaultOption);
};
