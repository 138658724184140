import styled from "@emotion/styled";
import tw from "twin.macro";
import { Link, useLocation } from "react-router-dom";

import useAuth from "app/hooks/useAuth";
import DashboardHeaderThemeButton from "./DashboardHeaderThemeButton";
import { useAppTheme } from "app/providers/AppThemeProvider";

import { DASHBOARD_HEADER_HEIGHT } from "app/constants/variables";
import { ReactComponent as LogoDark } from "app/assets/images/logo-dark.svg";
import { ReactComponent as LogoWhite } from "app/assets/images/logo-white.svg";

import Button from "./Button";
import ProfileDropdown from "./ProfileDropdown";
import { useProfile } from "app/providers/ProfileProvider";
import { gotoLogin, gotoSignup } from "app/lib/logout";

export default function PaymentLinksHeader() {
  const theme = useAppTheme();

  const isLoggedIn = useAuth();
  const profile = useProfile();
  const { pathname } = useLocation();

  const logo =
    theme === "dark" ? (
      <LogoWhite className="logo" />
    ) : (
      <LogoDark className="logo" />
    );

  return (
    <Container>
      <Link aria-label="payment-links" to={pathname}>
        {logo}
      </Link>
      <div className="secondary">
        {isLoggedIn && profile ? (
          <>
            <DashboardHeaderThemeButton />
            <ProfileDropdown />
          </>
        ) : (
          <>
            <DashboardHeaderThemeButton />
            <Button onClick={gotoLogin}>Log in</Button>
            <Button primary small onClick={gotoSignup}>
              Sign up
            </Button>
          </>
        )}
      </div>
    </Container>
  );
}

const Container = styled.nav`
  min-height: ${DASHBOARD_HEADER_HEIGHT}px;
  ${tw`w-full h-full px-[108px] flex items-center justify-between mobile:border-b tablet:px-[16px]`};

  ${tw`bg-white`};

  ${tw`dark:bg-black dark:border-b-white12`};

  .logo {
    ${tw`w-[124.176px] h-[32px] mobile:h-[24px] mobile:w-[93.132px]`}
  }

  .secondary {
    ${tw`flex items-stretch gap-[24px] items-center mobile:gap-[4px]`};
  }
`;
