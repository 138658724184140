import { PropsWithChildren } from "react";

import { ReactComponent as LogoDark } from "app/assets/images/logo-dark.svg";
import { ReactComponent as LogoWhite } from "app/assets/images/logo-white.svg";
import { useAppTheme } from "app/providers/AppThemeProvider";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";
import { HOME_PATH } from "app/constants/variables";

interface Props extends PropsWithChildren {
  noTheme?: boolean;
}

export default function FullPageLayout({ noTheme, children }: Props) {
  const navigate = useNavigate();
  const theme = useAppTheme();

  const handleExitClick = () => {
    navigate(HOME_PATH);
  };

  const logo =
    theme === "dark" || noTheme ? (
      <LogoWhite tw="h-[20px]" />
    ) : (
      <LogoDark tw="h-[20px]" />
    );

  return (
    <div tw="w-screen min-h-dvh h-full flex flex-col">
      <header tw="flex items-center justify-between border-b border-b-borderLow px-[24px] py-[14px] bg-surfaceBackground h-[48px] fixed w-full z-1 dark:bg-black dark:border-b-white12">
        {logo}

        <Button
          tertiary
          small
          onClick={handleExitClick}
          tw="dark:bg-black dark:border-white12 dark:text-white"
        >
          Exit
        </Button>
      </header>

      <main tw="grow pt-[96px] pb-[23px] px-[16px] sm:px-[32px]">
        {children}
      </main>
    </div>
  );
}
