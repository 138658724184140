import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { deleteUserPaymentAccount } from "app/api/payment_accounts";
import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";

export default function useDeletePaymentAccount(id: string) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const handleError = (err: AxiosError<any>) => {
    const errorMessage = parseError(err) as string;
    toast("", errorMessage, "error");
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries(["payment_accounts"]);
    toast("", "Payment account deleted", "success");
  };

  const { isLoading, data, mutate, error } = useMutation(
    ["delete-account", id],
    () => deleteUserPaymentAccount(id),
    {
      onError: handleError,
      onSuccess: handleSuccess,
    }
  );

  return {
    isLoading,
    data: data?.data,
    deleteAccount: mutate,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
  };
}
