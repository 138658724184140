import * as React from "react";

export interface CountdownOptions {
  enabled?: boolean;
  onCompleted?: () => void;
}

/**
 * @param timeout milliseconds till countdown
 */
const calcTimeRemaining = (milliSecondsToTimeout: number) => {
  const now = new Date().getTime();
  const distance = milliSecondsToTimeout - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return {
    days: Math.max(0, days),
    hours: Math.max(0, hours),
    minutes: Math.max(0, minutes),
    seconds: Math.max(0, seconds),
  };
};

/**
 * @param timeout milliseconds till countdown
 * @param options additionnal options
 */
export default function useCountdownTimer(
  defaultMilliSecondsToTimeout: number,
  options: CountdownOptions = { enabled: true }
) {
  const { enabled = true, onCompleted } = options;

  const [time, setTime] = React.useState(() =>
    calcTimeRemaining(new Date().getTime() + defaultMilliSecondsToTimeout)
  );

  // const isMounted = React.useRef(false);
  const intervalID = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    // console.log("_timeout :>> ", _timeout);
    // console.log("INITIAL");
    // console.log({ isM: isMounted.current });
    // if (isMounted.current !== false) return;
    // isMounted.current = true;

    // if (!enabled || intervalID.current) return;
    if (!enabled) return;

    // console.log("HERE");

    const timeout = new Date().getTime() + defaultMilliSecondsToTimeout;

    setTime(calcTimeRemaining(timeout));

    const _intervalID = (intervalID.current = setInterval(() => {
      // console.log("HERE");
      setTime(calcTimeRemaining(timeout));
    }, 1000));

    return () => {
      // console.log("cleanup HERE");
      clearInterval(_intervalID);
    };
  }, [enabled, defaultMilliSecondsToTimeout]);

  React.useEffect(() => {
    const { days, hours, minutes, seconds } = time;
    // console.log("time :>> ", time);

    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      // console.log("clear!!!!");
      clearInterval(intervalID.current);

      if (onCompleted) onCompleted();
    }
  }, [time, onCompleted]);

  const restart = React.useCallback(
    (milliSecondsToTimeout?: number) => {
      // console.log("RESTART");
      if (intervalID.current) {
        clearInterval(intervalID.current);
      }

      const timeout =
        new Date().getTime() +
        (milliSecondsToTimeout ?? defaultMilliSecondsToTimeout);

      setTime(calcTimeRemaining(timeout));

      const _intervalID = (intervalID.current = setInterval(() => {
        setTime(calcTimeRemaining(timeout));
      }, 1000));

      return () => {
        clearInterval(_intervalID);
      };
    },
    [defaultMilliSecondsToTimeout]
  );

  return {
    time,
    restart,
  };
}
