import styled from "@emotion/styled";
import tw from "twin.macro";
import { Link } from "react-router-dom";

import { ReactComponent as NotificationIcon } from "app/assets/icons/bell-outlined.svg";
import { ReactComponent as Ellipse } from "app/assets/icons/ellipse.svg";

const DashboardHeaderNotification = () => {
  return (
    <NotificationButton to="/notifications">
      <div tw="relative h-[24px] w-[24px] flex-shrink-0">
        <NotificationIcon tw=" w-[24px] h-[24px] self-center rounded-full object-contain" />

        <Ellipse tw="absolute right-[-0.5px] -top-px" />
      </div>
    </NotificationButton>
  );
};

const NotificationButton = styled(Link)`
  ${tw`w-[36px] h-[36px] flex items-center justify-center`};
`;

export default DashboardHeaderNotification;
