import { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";

import { useProfile } from "app/providers/ProfileProvider";
import { generateHMAC } from "app/lib/encryption";
import { INTERCOM_APP_SECRET } from "app/constants/variables";

import useAuth from "./useAuth";

const useInitializeIntercom = () => {
  const [isBooted, setIsBooted] = useState<boolean>();

  const profile = useProfile();
  const isLoggedIn = useAuth();
  const { boot, update, hardShutdown } = useIntercom();

  useEffect(() => {
    if (profile && !isBooted && isLoggedIn) {
      const hashedID = generateHMAC(profile.id, INTERCOM_APP_SECRET);

      boot({
        userId: profile.id,
        hideDefaultLauncher: true,
        userHash: hashedID,
      });
      setIsBooted(true);
    }
  }, [boot, isBooted, isLoggedIn, profile]);

  useEffect(() => {
    if (profile && isLoggedIn) {
      update({
        email: profile.email,
        name: `${profile.first_name} ${profile.last_name}`,
        phone: `${profile.phone}`,
      });
    }
  }, [isLoggedIn, profile, update]);

  useEffect(() => {
    if (!profile || !isLoggedIn) {
      hardShutdown();
      setIsBooted(false);
    }
  }, [hardShutdown, isLoggedIn, profile]);

  return { isBooted };
};

export default useInitializeIntercom;
