import tw, { css } from "twin.macro";

const GlobalStyles = css`
  * {
    margin: 0;
    padding: 0px;
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
  }

  *,
  body,
  button {
    margin: 0;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    ${tw`text-black95 dark:text-white95`};

    ${tw`bg-greyScale05 dark:bg-black`};
  }

  button {
    cursor: pointer;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .apexcharts-tooltip {
    background-color: transparent;
    border: none;
  }

  *::-webkit-scrollbar {
    display: block;
    width: 0px;
  }

  *::-webkit-scrollbar-thumb {
    ${tw`rounded-[6px] bg-greyScale80 dark:bg-greyScale05`};
  }

  html.dark input::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;

export default GlobalStyles;
