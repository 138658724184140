import tw, { styled } from "twin.macro";

import { ReactComponent as TrashIcon } from "app/assets/icons/trash-outline.svg";
import { ReactComponent as CaretRight } from "app/assets/icons/caret-right.svg";
import Card from "app/components/ui/Card";
import Header from "app/styles/Header";
import Text from "app/styles/Text";
import useModalState from "app/hooks/useModalState";
import Page from "app/components/ui/Page";
import useGoBack from "app/hooks/useGoBack";
import { ConnectPaymentAccount as PaymentAccount } from "app/models/paymentAccount";
import { countryMap } from "app/utils/mappers";
import DeleteAccountModal from "./DeleteAccountModal";

interface Props {
  account: PaymentAccount;
}

export default function NGNAccountDetails(props: Props) {
  const { account } = props;

  const { isOpen, toggleIsOpen, closeModal } = useModalState();

  const previousPage = useGoBack();

  const getFieldValue = (matchingName: string) =>
    account.fields.find((s) => s.display_name === matchingName)?.value;

  return (
    <Page breadCrumbs={{ label: "Back", onAction: previousPage }}>
      <div>
        <Header tw="text-[2.8rem] capitalize">
          {getFieldValue("Account Name")?.toLowerCase()}
        </Header>
        <Text medium subdued>
          {account.payment_method.currency} Account
        </Text>
      </div>

      <Details>
        <div>
          <Text subdued medium>
            Country
          </Text>

          <Text medium>
            {
              countryMap[
                account.payment_method.country as keyof typeof countryMap
              ]
            }
          </Text>
        </div>

        <div>
          <Text subdued medium>
            Bank
          </Text>

          <Text medium>{getFieldValue("Bank Name")}</Text>
        </div>

        <div>
          <Text subdued medium>
            Account number
          </Text>

          <Text medium>{getFieldValue("Account Number")}</Text>
        </div>

        <div>
          <Text subdued medium>
            Account name
          </Text>

          <Text medium tw="capitalize text-right">
            {getFieldValue("Account Name")?.toLowerCase()}
          </Text>
        </div>
      </Details>

      <Card
        tw="p-[20px] mt-[24px] flex items-center justify-between gap-[16px]"
        role="button"
        onClick={toggleIsOpen}
      >
        <TrashIcon width={24} height={24} color="#C00F00" />

        <Text tw="flex-1" medium>
          Delete account
        </Text>

        <CaretRight tw="text-black32 dark:text-white32" />
      </Card>

      <DeleteAccountModal
        accountId={account.id}
        isOpen={isOpen}
        onClose={closeModal}
      />
    </Page>
  );
}

const Details = styled(Card)`
  ${tw`p-[20px] mt-[24px] flex flex-col gap-[16px]`}

  > div {
    ${tw`flex justify-between items-baseline`}
  }
`;
