import { ConnectPaymentMethod } from "app/models/paymentMethod";

import request from "../request";
import { GenericResponse } from "../types";
import { GetPaymentMethodsParams } from "./types";

export const getPaymentMethods = (params: GetPaymentMethodsParams = {}) => {
  return request.get<GenericResponse<ConnectPaymentMethod[]>>(
    `/payment_methods`,
    { params }
  );
};
