import * as React from "react";
import { ButagOwner } from "app/models/butagOwner";

export const PaymentLinksRecipientContext =
  React.createContext<ButagOwner | undefined>(undefined);

PaymentLinksRecipientContext.displayName = "PaymentLinksRecipientContext";

export const SetPaymentLinksRecipientContext = React.createContext<
  React.Dispatch<React.SetStateAction<ButagOwner | undefined>>
>((_) => {});
SetPaymentLinksRecipientContext.displayName = "SetPaymentLinkRecipientContext";

export default function PaymentLinksRecipientProvider(
  props: React.PropsWithChildren<{}>
) {
  const [recipient, setRecipient] = React.useState<ButagOwner>();

  return (
    <PaymentLinksRecipientContext.Provider value={recipient}>
      <SetPaymentLinksRecipientContext.Provider value={setRecipient}>
        {props.children}
      </SetPaymentLinksRecipientContext.Provider>
    </PaymentLinksRecipientContext.Provider>
  );
}

export const usePaymentLinksRecipient = () => {
  const recipient = React.useContext(PaymentLinksRecipientContext);

  return recipient;
};

export const useSetPaymentLinksRecipient = () => {
  const setRecipient = React.useContext(SetPaymentLinksRecipientContext);

  return setRecipient;
};
