import { useNavigate } from "react-router-dom";

import { ReactComponent as File } from "app/assets/icons/file.svg";
import SubHeader from "app/styles/SubHeader";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";
import Card from "app/components/ui/Card";

export default function EmptyState() {
  const navigate = useNavigate();

  return (
    <Card tw="flex bg-transparent border-0 flex-col items-center justify-center gap-[10px] pb-[24px] mt-[24px]">
      <File />
      <SubHeader big>No accounts added yet</SubHeader>

      <Text medium subdued tw="text-center">
        Click on the button below to add a new
        <br /> account{" "}
      </Text>

      <Button small primary tw="mt-[5px]" onClick={() => navigate("add")}>
        Add new account
      </Button>
    </Card>
  );
}
