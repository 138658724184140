import * as React from "react";
import Joyride, { Step } from "react-joyride";

import { getScreenWidth } from "app/utils/helpers";
import { useAppTheme } from "app/providers/AppThemeProvider";
import { ALICE_TRADE_TOUR_KEY } from "app/constants/variables";
import storage from "app/lib/storage";

export interface TourState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
}

export const getDefaultSteps = (theme: string): Step[] => [
  {
    content: (
      <div>
        <div tw="w-full mt-[4px] text-left text-black dark:text-white">
          <h2 tw="font-bold mb-[4px]">Brand new Trade Tab</h2>
          <p tw="text-[12px] text-[#666]">
            View your trades here or start a new one.
          </p>
        </div>
      </div>
    ),
    disableBeacon: true,
    placement: getScreenWidth() > 768 ? "right" : "top",
    styles: {
      options: {
        width: 320,
      },
      buttonNext: {
        color: theme === "dark" ? "#000" : "#FFF",
        fontWeight: 600,
      },
      tooltip: {
        height: 124,
        padding: 12,
        borderRadius: 8,
        background: theme === "dark" ? "#000" : "#fff",
        boxShadow:
          theme === "dark" ? "0px 0px 40px rgba(255, 255, 255, 0.1)" : "none",
      },
      buttonClose: {
        color: theme === "dark" ? "#FFF" : "#000",
      },
      tooltipContent: {
        padding: 0,
      },
      spotlight: {
        borderRadius: 8,
        border: `1px solid ${theme === "dark" ? "#C0C0C0" : "none"}`,
      },
    },
    target: "#step-1",
  },
];

const defaultTourState: TourState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export const TourGuideContext =
  React.createContext<TourState>(defaultTourState);
TourGuideContext.displayName = "TourGuideContext";

export const SetTourGuideContext = React.createContext<
  (state: Partial<TourState>) => void
>(() => {});

SetTourGuideContext.displayName = "SetTourGuideContext";

export function TourGuideProvider({ children }: React.PropsWithChildren) {
  const theme = useAppTheme();

  const [state, setState] = React.useState({
    ...defaultTourState,
    steps: getDefaultSteps(theme),
  });

  const updateTourState = React.useCallback((newState: Partial<TourState>) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  React.useEffect(() => {
    const tour = storage.get(ALICE_TRADE_TOUR_KEY);

    if (tour || (tour && tour === "inactive")) return;

    const timeoutId = setTimeout(() => {
      updateTourState({ run: true, stepIndex: 0 });

      storage.set(ALICE_TRADE_TOUR_KEY, "active");
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [updateTourState]);

  const handleJoyrideCallback = React.useCallback(
    (data: any) => {
      const { action, index, status } = data;

      if (status === "finished" || status === "skipped") {
        updateTourState({ run: false, tourActive: false });
      } else if (action === "next") {
        updateTourState({ stepIndex: index + 1 });
      }
    },
    [updateTourState]
  );

  return (
    <>
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        steps={state.steps}
        run={state.run}
        hideBackButton={true}
        disableScrolling={true}
        stepIndex={state.stepIndex}
        spotlightClicks={false}
        styles={{
          options: {
            backgroundColor: theme === "dark" ? "#0d0d0d" : "#FFF",
            overlayColor: "rgba(13, 13, 13, 0.30)",
            primaryColor: "#12A232",
            arrowColor: theme === "dark" ? "#000" : "#FFF",
          },
        }}
        locale={{
          last: "Got it",
        }}
      />
      <TourGuideContext.Provider value={state}>
        <SetTourGuideContext.Provider value={updateTourState}>
          {children}
        </SetTourGuideContext.Provider>
      </TourGuideContext.Provider>
    </>
  );
}

export function useTourGuideContext() {
  const context = React.useContext(TourGuideContext);
  if (!context) {
    throw new Error(
      "useTourGuideContext must be used within a TourGuideProvider"
    );
  }

  return context;
}

export function useSetTourGuideContext() {
  const context = React.useContext(SetTourGuideContext);
  if (!context) {
    throw new Error(
      "useSetTourGuideContext must be used within a TourGuideProvider"
    );
  }

  return context;
}
