import * as React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import SelectOption from "app/models/selectOption";
import Text from "app/styles/Text";
import TextField from "app/components/ui/TextField";
import Button from "app/components/ui/Button";
import LoaderContainer from "app/components/ui/LoaderContainer";
import Loader from "app/components/ui/Loader";
import AutoComplete from "app/components/ui/AutoComplete";
import Card from "app/components/ui/Card";
import useGetAllBanks from "app/hooks/useGetAllBanks";
import useBanks from "app/hooks/useBanks";
import usePaymentAccount from "app/hooks/usePaymentAccount";
import { ConnectPaymentMethod } from "app/models/paymentMethod";
import Header from "app/styles/Header";
import EnableTwoStepAuthModal from "../../../components/EnableTwoStepAuthModal";
import { GenericResponse } from "app/api/types";

interface Props {
  accountMethod: ConnectPaymentMethod;
  onSuccess: (d: GenericResponse<{ token: string; message: string }>) => void;
}

export default function AddNGNAccount(props: Props) {
  const { accountMethod, onSuccess } = props;

  const [selectedBank, setSelectedBank] = React.useState<string>("");
  const [accountNumber, setAccountNumber] = React.useState<string>("");

  const {
    isLoading: isLoadingAllBanks,
    banks,
    error,
    getBanks,
  } = useGetAllBanks();

  const {
    verifyAccount,
    verifyingAccountDetails,
    verificationResponse,
    verificationError,
    resetVerification,
  } = useBanks();

  const { initialize, isInitializing } = usePaymentAccount();

  const updateAccountNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.trim() === "" || (!isNaN(Number(value)) && value.length <= 10)) {
      setAccountNumber(value);
      resetVerification();
      if (value.length === 10 && selectedBank) {
        verifyAccount({
          account_number: value,
          bank_code: selectedBank,
        });
      }
    }
  };

  const updateSelectedBank = (opt: SelectOption) => {
    setSelectedBank(opt.value);
    setAccountNumber("");
    resetVerification();
  };

  const addPaymentAccount = () => {
    const correspondingBank = (banks || []).find(
      (b) => b.bank_code === selectedBank
    );

    if (correspondingBank && selectedBank && verificationResponse) {
      initialize(
        {
          payment_method: accountMethod.id,
          fields: [
            { name: "account_number", value: accountNumber },
            { name: "bank_code", value: selectedBank },
            { name: "bank_name", value: correspondingBank?.bank_name },
            { name: "account_name", value: verificationResponse?.account_name },
          ],
        },
        {
          onSuccess: (data) => {
            onSuccess(data.data);
          },
        }
      );
    }
  };

  const disabledBtn = !verificationResponse;

  return (
    <>
      <Header tw="text-[2.8rem] leading-none mb-[32px]">Bank account</Header>

      <Card tw="p-[20px]">
        <LoaderContainer
          loading={isLoadingAllBanks}
          error={!!error}
          errorMessage={error}
          onRetry={getBanks}
        >
          {banks && (
            <FormContainer
              onSubmit={(e) => {
                e.preventDefault();
                addPaymentAccount();
              }}
            >
              <AutoComplete
                label="Bank account"
                placeholder="--Search Bank--"
                value={selectedBank}
                onChange={updateSelectedBank}
                options={banks.map((b) => ({
                  label: b.bank_name,
                  value: b.bank_code,
                }))}
              />

              <TextField
                label="Account number"
                placeholder="Enter account number"
                value={accountNumber}
                onChange={updateAccountNumber}
                error={!!verificationError}
                errorMessage={verificationError}
              />

              {verifyingAccountDetails && (
                <div tw="flex justify-center py-[10px]">
                  <Loader size="20" />
                </div>
              )}

              {verificationResponse && (
                <AccountName>
                  <label htmlFor="accountname">Account name</label>
                  <div>
                    <Text medium> {verificationResponse.account_name} </Text>
                  </div>
                </AccountName>
              )}

              <div tw="pt-[16px] border-t border-[rgba(0, 0, 0, 0.12)] dark:border-gray-800">
                <Button
                  primary
                  fullWidth
                  disabled={disabledBtn}
                  type="submit"
                  loading={isInitializing}
                >
                  Save payment account
                </Button>
              </div>
            </FormContainer>
          )}
        </LoaderContainer>
      </Card>

      <EnableTwoStepAuthModal />
    </>
  );
}

const FormContainer = styled.form`
  ${tw`flex flex-col gap-[16px]`};
`;

const AccountName = styled.div`
  ${tw`max-w-full`};

  > label {
    ${tw`block mb-[8px] text-[1.6rem]`};
  }

  > div {
    ${tw`w-full px-[16px] py-[10px] rounded-[4px] border border-[#0000001F] transition-colors`};
    ${tw`bg-greyScale10 dark:bg-[#000] text-black60 dark:text-white60`}
    ${tw`dark:border-white30 dark:focus-within:border-white`};
  }
`;
