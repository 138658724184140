export const TOKEN_STORAGE_KEY = "tokens";

export const SIGN_OUT_PATH = "signout";
export const SIGN_UP_PATH = "signup";
export const LOGIN_PATH = "login";
export const HOME_PATH = "/";
export const PAYMENT_LINKS_PATH = "pay";
export const KYB_PATH = "business-verification";

export const APP_THEME_STORAGE_KEY =
  process.env.REACT_APP_THEME_STORAGE_KEY ?? "preferred-theme";

export const USER_BALANCE_STATE_KEY =
  process.env.REACT_APP_BALANCE_STATE_KEY ?? "balance-state";
export const USER_EMPTY_WALLETS_STATE_KEY =
  process.env.REACT_APP_EMPTY_WALLETS_STATE_KEY ?? "empty-wallets-state";

export const COUNTRY_FLAG_BASE_URL =
  "https://res.cloudinary.com/busha-inc/image/upload/v1730518853/alice/v4/country-flags";

export const COIN_IMG_BASE_URL =
  "https://res.cloudinary.com/busha-inc/image/upload/v1628857343/App/Currencies";

export const CROSSELLER_DEFAULT_ICON =
  "https://res.cloudinary.com/busha-inc/image/upload/v1699260209/Illustrations_6.svg";
export const REACTIVATION_PROMOTION_IMAGE =
  "https://res.cloudinary.com/busha-inc/image/upload/v1720440295/alice/v4/reactivate-your-account.png";
export const FIAT_CURRENCY_ICON =
  "https://res.cloudinary.com/busha-inc/image/upload/v1702448856/yousend/landing";

export const FPL_BANNER_IMAGE_NG =
  "https://res.cloudinary.com/busha-inc/image/upload/v1724680456/announcements/FPL_NGA_Dark.png";

export const FPL_BANNER_IMAGE_KE =
  "https://res.cloudinary.com/busha-inc/image/upload/v1724861486/announcements/FPL_KE.png";

export const BUSHA_FEST_CAMPAIGN =
  "https://res.cloudinary.com/busha-inc/image/upload/v1732113143/announcements/BUSHA_FEST_CAMPAIGN.png";

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const INTERCOM_APP_SECRET =
  process.env.REACT_APP_INTERCOM_APP_SECRET ?? "";

export const AUTH_URL = process.env.REACT_APP_AUTH_API_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const RECAPTCHA_SITE_KEY_V3 =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3;

export const ANNOUNCEMENTS_KEY = "announcements";

export const BANK_IMG_BASE_URL =
  "https://res.cloudinary.com/busha-inc/image/upload/v1647829486/App/Connect%20Payment%20Methods/bank.png";

export const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL;

export const UNAUTHORIZED_ERROR_CODE = 401;
export const UNFOUND_ROUTE = 404;

export const DASHBOARD_HEADER_HEIGHT = 64;

export const DASHBOARD_BODY_PADDING_X = 32;
export const DASHBOARD_BODY_PADDING_Y = 24;

export const DASHBOARD_BODY_PADDING_MOBILE_X = 16;
export const DASHBOARD_BODY_PADDING_MOBILE_Y = 12;

export const PAYMENT_LINKS_BODY_PADDING_X = 32;
export const PAYMENT_LINKS_BODY_PADDING_Y = 48;

export const PAYMENT_LINKS_BODY_PADDING_MOBILE_X = 16;
export const PAYMENT_LINKS_BODY_PADDING_MOBILE_Y = 20;

export const DASHBOARD_SIDEBAR_WIDTH = 262;

export const DASHBOARD_MOBILE_NAV_HEIGHT = 94;

export const DEFAULT_PROFILE_IMG =
  "https://res.cloudinary.com/busha-inc/image/upload/v1668529176/alice/v4/user.png";

export const DEFAULT_ERROR_MESSAGE = "Error Occured";

export const DEFAULT_DATE_QUERY_FORMAT = "YYYY-MM-DD";

export const FIREBASE_CONFIG = {
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const FEATURE_IMAGES_BASE_URL =
  "https://res.cloudinary.com/busha-inc/image/upload/v1694510938/alice/v4/features";

export const HIDDEN_BALANCE_STRING = "******";

export const DASHBOARD_ROUTES_ID = "dashboard-routes";

export const AUTH_APP_URL = process.env.REACT_APP_AUTH_APP_URL;

type BushaEnvType = typeof process.env.NODE_ENV | "staging";

export const BUSHA_ENV =
  (process.env.REACT_APP_BUSHA_ENV as BushaEnvType) ?? "development";

export const AIRTABLE_KEY = process.env.REACT_APP_AIRTABLE_KEY;

export const GTM_CONTAINER_ID = process.env.REACT_APP_GTM_CONTAINER_ID;

export const ACCOUNT_SETUP_COMPLETION_KEY = "setup-complete";

export const BIRTHDAY_MODAL_KEY = "has-shown-birthday-modal";

export const ACCOUNT_UPGRADE_ERROR = "level_upgrade_required";

export const AIRTABLE_BASE_ID = "appgi0CMFqIpYyDjH";

export const MEDIA_SERVICE_BASE_URL =
  process.env.REACT_APP_MEDIA_SERVICE_BASE_URL;

export const PROFILE_STORAGE_KEY = "profile";

export const ACTIVE_PROFILE_STORAGE_KEY = "active-profile";

export const ALICE_TRADE_TOUR_KEY = "AliceTradeTour";
export const MOENGAGE_APP_ID = process.env.REACT_APP_MOENGAGE_APP_ID ?? "";

export const SEC_LOGO =
  "https://res.cloudinary.com/busha-inc/image/upload/v1728993508/alice/v4/SEC_logo.png";

export const BUSHA_RISK_WARNING_URL =
  "https://www.busha.ng/busha-digital-limited-risk-warning";

export const BUSHA_TERMS_AND_POLICIES_URL =
  "https://www.busha.ng/terms-and-policies";

export const BUSHA_AML_KYC_POLICY_URL = "https://www.busha.ng/aml-kyc-policy";
