import { ConnectPaymentAccount } from "app/models/paymentAccount";
import qs from "qs";

import request from "../request";
import {
  GenericPaginatedResponse,
  BaseResponse,
  GenericResponse,
} from "../types";
import {
  CreateUserPaymentAccountPayload,
  GetUserPaymentAccountsParams,
} from "./types";

export const getUserPaymentAccounts = (
  params?: GetUserPaymentAccountsParams
) => {
  return request.get<GenericPaginatedResponse<ConnectPaymentAccount>>(
    `/payment_accounts`,
    {
      params: {
        expand: "payment_method",
        ...params,
      },
      paramsSerializer: {
        serialize: (p) => qs.stringify(p, { arrayFormat: "comma" }),
      },
    }
  );
};

export const getUserPaymentAccount = (id: string) => {
  return request.get<GenericResponse<ConnectPaymentAccount>>(
    `/payment_accounts/${id}`,
    {
      params: {
        expand: "payment_method.fields",
      },
    }
  );
};

export const createUserPaymentAccount = (
  payload: CreateUserPaymentAccountPayload
) => {
  return request.post<BaseResponse>(`/payment_accounts`, payload);
};

export const initializeCreateUserPaymentAccount = (
  payload: CreateUserPaymentAccountPayload
) => {
  return request.post<GenericResponse<{ token: string; message: string }>>(
    `/payment_accounts/initialize`,
    payload
  );
};

export const finalizeCreateUserPaymentAccount = (payload: {
  token: string;
  code: string;
}) => {
  return request.post<BaseResponse>(`/payment_accounts/finalize`, payload);
};

export const updateUserPaymentAccount = (
  id: string,
  payload: CreateUserPaymentAccountPayload
) => {
  return request.put<BaseResponse>(`/payment_accounts/${id}`, payload);
};

export const deleteUserPaymentAccount = (id: string) => {
  return request.delete<BaseResponse>(`/payment_accounts/${id}`);
};
