import * as Sentry from "@sentry/react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { ReactNode, useEffect, useRef } from "react";

import media from "app/styles/media";
import useAuth from "app/hooks/useAuth";
import useGetProfile from "app/hooks/useGetProfile";

import {
  DASHBOARD_BODY_PADDING_Y,
  DASHBOARD_HEADER_HEIGHT,
  PAYMENT_LINKS_BODY_PADDING_MOBILE_X,
  PAYMENT_LINKS_BODY_PADDING_MOBILE_Y,
  PAYMENT_LINKS_BODY_PADDING_X,
  PAYMENT_LINKS_BODY_PADDING_Y,
} from "app/constants/variables";

import LoaderContainer from "./ui/LoaderContainer";
import ErrorWidget from "./ui/ErrorWidget";
import useGetButagOwner from "app/hooks/useGetButagOwner";
import { usePaymentLinksRecipient } from "app/providers/PaymentLinksRecipientProvider";

const PaymentLinksErrorWidget = (props: any) => {
  return <ErrorWidget {...props} dashboard />;
};

interface Props {
  header: ReactNode;
}

export default function PaymentLinksLayout(props: Props) {
  const { header } = props;

  const { butag } = useParams<{ butag: string }>();

  const contentRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const isLoggedIn = useAuth();

  const { isInitialLoading } = useGetProfile(isLoggedIn, {
    ignoreSignOut: true,
  });
  const {
    isLoading: isLoadingButag,
    error: butagError,
    getOwner,
  } = useGetButagOwner({
    butag: butag as string,
    relativeToPaymentLinks: true,
  });

  const paymentLinksRecipient = usePaymentLinksRecipient();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scroll(0, 0);
    }
  }, [location.pathname]);

  return (
    <LoaderContainer
      screen
      loading={isInitialLoading || isLoadingButag}
      error={!!butagError}
      errorMessage={butagError}
      onRetry={getOwner}
    >
      {paymentLinksRecipient && (
        <Container>
          <header className="header">{header}</header>

          <section className="body">
            <Sentry.ErrorBoundary
              fallback={PaymentLinksErrorWidget}
              beforeCapture={(scope) => {
                scope.setTag("location", "payment-links");
              }}
            >
              <main className="content" ref={contentRef}>
                <Outlet />
              </main>
            </Sentry.ErrorBoundary>
          </section>
        </Container>
      )}
    </LoaderContainer>
  );
}

const Container = styled.section`
  ${tw`w-full h-screen flex flex-col`};
  ${tw`bg-greyScale05`};

  ${tw`dark:bg-black`};

  > .header {
    height: ${DASHBOARD_HEADER_HEIGHT}px;
    position: sticky;
    top: 0;
    ${tw`z-10`};
  }

  > .body {
    ${tw`flex-grow w-full flex`};
    height: calc(100vh - ${DASHBOARD_HEADER_HEIGHT}px);
  }

  > .body > .content {
    ${tw`w-full h-full overflow-x-hidden overflow-y-auto max-w-[600px] mx-auto`};

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    padding-left: ${PAYMENT_LINKS_BODY_PADDING_X}px;
    padding-right: ${PAYMENT_LINKS_BODY_PADDING_X}px;

    padding-top: ${PAYMENT_LINKS_BODY_PADDING_Y}px;
    /* padding-bottom: ${DASHBOARD_BODY_PADDING_Y}px; */

    ${media.mobile} {
      padding-left: ${PAYMENT_LINKS_BODY_PADDING_MOBILE_X}px;
      padding-right: ${PAYMENT_LINKS_BODY_PADDING_MOBILE_X}px;

      padding-top: ${PAYMENT_LINKS_BODY_PADDING_MOBILE_Y}px;
      padding-bottom: ${PAYMENT_LINKS_BODY_PADDING_MOBILE_Y}px;
    }
  }
`;
