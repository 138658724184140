import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { getProfile } from "app/api/profile";
import parseError from "app/lib/parseError";
import { useSetProfile, useProfile } from "app/providers/ProfileProvider";
import { UNAUTHORIZED_ERROR_CODE } from "app/constants/variables";
import { useSetToken } from "app/providers/TokenProvider";
import { dispatchGTMEvent } from "app/lib/gtm";
interface MetaProps {
  ignoreSignOut?: boolean;
}

const useGetProfile = (enabled = true, meta: MetaProps = {}) => {
  const setProfile = useSetProfile();
  const profile = useProfile();
  const setAppTokens = useSetToken();

  const { ignoreSignOut } = meta;

  const {
    data,
    status,
    error,
    refetch,
    isFetching,
    isLoading,
    isInitialLoading,
    isRefetching,
  } = useQuery(["profile"], getProfile, {
    enabled,
    onSuccess: async (data) => {
      setProfile(data.data);

      const { id, email } = data.data;

      await dispatchGTMEvent("login", { user_id: id, email: email });
    },
    onError: (error: AxiosError<{}>) => {
      if (error.response?.status === UNAUTHORIZED_ERROR_CODE && ignoreSignOut) {
        setAppTokens({});
      }
    },
    placeholderData: () => {
      if (!profile) return undefined;

      return { data: profile };
    },
  });

  return {
    profile: data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    isFetching,
    isLoading,
    isInitialLoading,
    isRefetching,
  };
};

export default useGetProfile;
