import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { getAccounts } from "app/api/accounts";
import { ACCOUNTS_REQUEST_QUERY } from "app/constants/commonQueries";

const useGetAccounts = () => {
  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    [ACCOUNTS_REQUEST_QUERY],
    getAccounts
  );

  return {
    accounts: data?.data.data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getAccounts: refetch,
    isFetching,
    isLoading,
  };
};

export default useGetAccounts;
