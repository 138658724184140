import { GenericResponse } from "app/api/types";
import PinInput from "app/components/ui/PinInput";
import usePaymentAccount from "app/hooks/usePaymentAccount";
import Header from "app/styles/Header";
import Text from "app/styles/Text";

interface Props {
  initializeResponse?: GenericResponse<{ token: string; message: string }>;
  onSuccess: () => void;
}

export default function TwoFactorAuthInput(props: Props) {
  const { initializeResponse, onSuccess } = props;

  const { finalize, isFinalizing, finalizeError } = usePaymentAccount();

  return (
    <>
      <Header tw="text-[2.8rem] mb-[8px]">Security authentication</Header>

      <Text big subdued>
        {initializeResponse?.data.message}
      </Text>

      <PinInput
        tw="mt-[27px]"
        onPinCompleted={(c: string) =>
          finalize(
            {
              token: initializeResponse?.data.token as string,
              code: c,
            },
            {
              onSuccess: () => onSuccess(),
            }
          )
        }
        disabled={isFinalizing}
        error={!!finalizeError}
        errorMessage={finalizeError}
      />
    </>
  );
}
