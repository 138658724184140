import tw, { styled } from "twin.macro";

import Card from "app/components/ui/Card";
import Text from "app/styles/Text";
import { ReactComponent as CaretRight } from "app/assets/icons/caret-right.svg";
import { FIAT_CURRENCY_ICON } from "app/constants/variables";
import Header from "app/styles/Header";
import useGetPaymentMethods from "app/hooks/useGetPaymentMethods";
import LoaderContainer from "app/components/ui/LoaderContainer";
import { ConnectPaymentMethod } from "app/models/paymentMethod";

interface Props {
  onContinue: (b: ConnectPaymentMethod) => void;
}

const countryNameMap = {
  NG: "Nigeria",
  US: "United States of America",
};

const flagMap = {
  NG: "NGN",
  US: "USD",
};

export default function SelectBankAccount(props: Props) {
  const { onContinue } = props;

  const { methods, isLoading, error, refetchPaymentMethods } =
    useGetPaymentMethods({
      category: "bank_account",
    });

  const account_methods = methods?.filter((item) => item.type !== "business");

  return (
    <LoaderContainer
      loading={isLoading}
      error={!!error}
      onRetry={refetchPaymentMethods}
    >
      <Header tw="mb-[24px] text-[28px]">Select currency</Header>

      <Card tw="mt-[8px] flex flex-col p-[16px] gap-[16px]">
        {account_methods &&
          account_methods.map((method) => (
            <Method onContinue={onContinue} method={method} key={method.id} />
          ))}
      </Card>
    </LoaderContainer>
  );
}

const Method = ({
  method,
  onContinue,
}: {
  method: ConnectPaymentMethod;
  onContinue: Props["onContinue"];
}) => {
  return (
    <MethodTile role="button" onClick={() => onContinue(method)}>
      <div tw="flex items-center gap-[16px]">
        <img
          src={`${FIAT_CURRENCY_ICON}/${
            flagMap[method.country as keyof typeof flagMap]
          }`}
          alt={method.country}
          tw=" w-[40px] h-[40px] self-center rounded-full object-contain"
        />

        <div>
          <Text medium tw="uppercase">
            {method.currency}
          </Text>

          <Text tw="text-black32" subdued>
            {countryNameMap[method.country as keyof typeof countryNameMap]}
          </Text>
        </div>
      </div>

      <CaretRight tw="text-black32 dark:text-white32" />
    </MethodTile>
  );
};

const MethodTile = styled.div`
  ${tw`flex justify-between items-center not-last-of-type:border-b border-[#00000014] dark:border-[#FFFFFF14] not-last-of-type:pb-[16px]`}
`;
