const isEmpty = (item: unknown): boolean => {
  if (typeof item === "undefined") {
    return true;
  }
  if (typeof item === "string" || Array.isArray(item)) {
    return item.length === 0;
  }
  if (item === null) {
    return true;
  }
  if (typeof item === "object") {
    return Object.keys(item).length === 0;
  }

  if (typeof item === "boolean") {
    return item !== true;
  }

  return false;
};

export default isEmpty;
