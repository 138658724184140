import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";
import clsx from "clsx";
import { Combobox } from "@headlessui/react";

import SubHeader from "app/styles/SubHeader";
import Text from "app/styles/Text";
import Paths from "app/utils/paths";
import { formatMoney } from "app/lib/money";
import { ReactComponent as ArrowDown } from "app/assets/icons/arrow-down-right.svg";
import { ReactComponent as ArrowUp } from "app/assets/icons/arrow-up-right.svg";
import { AssetsShimmer } from "./Shimmer";
import useSearchCurrencies from "app/hooks/useSearchCurrencies";

export default function TopAssets() {
  const { currencies, isFetching } = useSearchCurrencies({
    filter: "top_movers",
    limit: 4,
  });

  const topMovers = currencies?.data ?? [];

  return (
    <div>
      <SubHeader tw="mb-[16px]">Top Assets</SubHeader>

      {isFetching ? (
        <AssetsShimmer />
      ) : (
        <div>
          {topMovers?.map(({ base, name, percentage_change, latest_price }) => (
            <ListItem key={`${base}-${name}`} to={`spot/${base}`}>
              <Combobox.Option value={name} tw="list-none">
                <div className="item">
                  <div className="coin">
                    <img src={`${Paths.coinSVG(base)}`} alt={name} />

                    <div>
                      <Text medium>{base}</Text>
                      <Text className="coin--name">{name}</Text>
                    </div>
                  </div>
                  <div className="coin--value">
                    <Text>
                      {formatMoney(
                        Number(latest_price.amount.amount),
                        "fiat",
                        latest_price.amount.currency
                      )}
                    </Text>

                    <Text
                      className={clsx(
                        { negative: Number(percentage_change) < 0 },
                        { neutral: Number(percentage_change) === 0 },
                        "coin--rate"
                      )}
                    >
                      {Number(percentage_change) < 0 ? (
                        <ArrowDown />
                      ) : (
                        Number(percentage_change) > 0 && <ArrowUp />
                      )}
                      {percentage_change}%
                    </Text>
                  </div>
                </div>
              </Combobox.Option>
            </ListItem>
          ))}
        </div>
      )}
    </div>
  );
}

const ListItem = styled(Link)`
  .item {
    display: flex;
    padding: 16px 0px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(91, 97, 110, 0.2);

    .coin {
      display: flex;
      align-items: center;
      column-gap: 12px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 32px;
      }

      &--name {
        ${tw`text-black32 dark:text-white32`}
      }

      &--rate {
        ${tw`text-primary70 flex items-center`}

        &.negative {
          ${tw`text-error70 dark:text-error50`};
        }

        &.neutral {
          ${tw`text-black60 dark:text-white60`};
        }
      }

      &--value {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        svg {
          display: inline;
          margin-right: 2px;

          ${tw`w-[16px] h-[16px] text-primary70`};

          path {
            ${tw`fill-current`};
          }
        }
      }
    }
  }

  &:last-of-type {
    .item {
      border: none;
      padding-bottom: 0px;
    }
  }
`;
