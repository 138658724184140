import { useNavigate } from "react-router-dom";

import Button from "app/components/ui/Button";
import Modal from "app/components/ui/Modal";
import Header from "app/styles/Header";
import Text from "app/styles/Text";
import useDeletePaymentAccount from "app/hooks/useDeletePaymentAccount";

interface DeleteAccountProps {
  isOpen: boolean;
  onClose: VoidFunction;
  accountId: string;
}

export default function DeleteAccountModal(props: DeleteAccountProps) {
  const { isOpen, onClose, accountId } = props;

  const navigate = useNavigate();

  const { deleteAccount, isLoading } = useDeletePaymentAccount(accountId!);

  const onDelete = () => {
    deleteAccount(undefined, {
      onSuccess: () => {
        onClose();
        navigate("/profile/payment-methods/bank-accounts");
      },
    });
  };

  return (
    <Modal
      tw="max-w-[480px]"
      showClose={false}
      open={isOpen}
      onClose={() => {}}
    >
      <div tw="p-[24px] flex flex-col items-center gap-[24px]">
        <div tw="text-center">
          <Header tw="max-w-[432px] leading-[28px] mb-[8px]">
            Are you sure you want to delete this account?
          </Header>

          <Text medium subdued>
            This is irreversible. Are you sure you want to continue?
          </Text>
        </div>

        <div tw="w-full flex flex-col gap-[8px]">
          <Button fullWidth tertiary onClick={onClose}>
            Cancel
          </Button>
          <Button fullWidth danger onClick={onDelete} loading={isLoading}>
            Yes, delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
