import "twin.macro";
import { forwardRef, InputHTMLAttributes, ReactNode, useState } from "react";
import tw, { styled } from "twin.macro";
import clsx from "clsx";

import Text from "app/styles/Text";
import uniqid from "app/lib/uniqid";

type HTMLInputType = InputHTMLAttributes<HTMLInputElement>;

interface Props extends Omit<HTMLInputType, "prefix"> {
  type?: HTMLInputType["type"];
  label: string;
  labelHidden?: boolean;
  assistiveText?: string;
  error?: boolean;
  errorMessage?: string;
  containerClassName?: string;
  currency?: string;
  balance?: string;
  currencyAction?: VoidFunction;
  prefix?: ReactNode;
  suffix?: ReactNode;
  connectedLeft?: ReactNode;
  className?: string;
  connectedRight?: ReactNode;
}
function TextField(props: Props, ref: any) {
  const {
    label,
    labelHidden = false,
    id = uniqid(),
    containerClassName,
    className,
    assistiveText,
    balance,
    currency,
    error,
    errorMessage,
    currencyAction,
    type,
    prefix,
    suffix,
    connectedLeft,
    disabled,
    connectedRight,
    ...rest
  } = props;

  const isPasswordTextField = type === "password";
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container className={containerClassName}>
      <label htmlFor={id} className={labelHidden ? "hidden" : undefined}>
        {label}
      </label>

      <div tw="flex gap-[8px]">
        {connectedLeft}

        <InputContainer className={clsx({ error, disabled })}>
          <div className={className}>
            {prefix}

            <input
              ref={ref}
              id={id}
              disabled={disabled}
              {...rest}
              type={
                isPasswordTextField
                  ? showPassword
                    ? "text"
                    : "password"
                  : type
              }
              value={props.value}
              onChange={props.onChange}
              inputMode={props.inputMode}
            />

            {isPasswordTextField ? (
              <button
                type="button"
                className="password-button"
                onClick={() => setShowPassword((s) => !s)}
              >
                {showPassword ? "HIDE" : "SHOW"}
              </button>
            ) : null}

            {suffix}
          </div>
        </InputContainer>

        {connectedRight}
      </div>

      {!!assistiveText ? (
        <Text className="assistive-text">{assistiveText}</Text>
      ) : null}

      {!!errorMessage ? (
        <Text className="error-message">{errorMessage}</Text>
      ) : null}
    </Container>
  );
}

export default forwardRef(TextField);

const Container = styled.div`
  ${tw`max-w-full`};

  > label {
    ${tw`block mb-[8px] text-[1.6rem]`};

    &.hidden {
      ${tw`hidden`};
    }
  }

  > p {
    ${tw`mt-[8px]`};

    &.assistive-text {
      ${tw` text-black60 dark:text-white60`};
    }

    &.error-message {
      ${tw`text-error70 dark:text-error50`};
    }
  }
`;

const InputContainer = styled.div`
  ${tw`w-full px-[16px] py-[10px] rounded-[4px] border border-[#0000001F] focus-within:border-black transition-colors flex-grow`};

  ${tw`dark:border-white30 dark:focus-within:border-white`};

  &.error {
    ${tw`border-error70 dark:border-error50`};
  }

  > div {
    ${tw`flex items-center gap-[12px]`};
  }

  &.disabled {
    ${tw`bg-[#F2F2F2] border-[#E5E5E5] text-[#B2B2B2] dark:bg-[#0D0D0D] dark:border-[#1A1A1A] dark:text-[#4D4D4D]`}
  }

  input {
    ${tw`w-full bg-transparent outline-none text-[1.6rem] placeholder:text-black32`};

    ${tw`dark:placeholder:text-white32`};

    &:disabled {
      ${tw`cursor-not-allowed`}
    }

    &:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        transition: background-color 600000s 0s, color 600000s 0s;
      }
    }

    &[data-autocompleted] {
      background-color: transparent !important;
    }
  }

  .password-button {
    ${tw`text-[1.6rem] font-semibold`};
  }

  p.balance {
    ${tw`text-right`};
  }
`;
