import tw, { styled } from "twin.macro";
import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";
import { Link } from "react-router-dom";

import Header from "app/styles/Header";
import SubHeader from "app/styles/SubHeader";
import media from "app/styles/media";
import { isString } from "app/utils/helpers";
import { ReactComponent as ChevronLeft } from "app/assets/icons/chevron-left.svg";
import { DASHBOARD_BODY_PADDING_Y } from "app/constants/variables";

type PagebradCrumb = {
  label: string;
  onAction?: () => void;
  url?: string;
};

export interface PageProps extends ContainerProps {
  title?: ReactNode;
  subTitle?: ReactNode;
  secondaryAction?: JSX.Element;
  headerImageURL?: string;
  breadCrumbs?: PagebradCrumb;
}
export default function Page(props: PropsWithChildren<PageProps>) {
  const {
    children,
    fullWidth,
    title,
    subTitle,
    headerImageURL,
    breadCrumbs,
    secondaryAction,
  } = props;

  const showHeader = Boolean(
    title || subTitle || headerImageURL || breadCrumbs
  );

  const backContent = (
    <>
      <ChevronLeft /> {breadCrumbs?.label}
    </>
  );

  return (
    <Container fullWidth={fullWidth}>
      {showHeader && (
        <div className="header">
          <div tw="flex flex-row justify-between items-center">
            {!!breadCrumbs && (
              <div
                className={clsx("breadcrumbs", {
                  "has-spacing": title || subTitle || headerImageURL,
                })}
              >
                {breadCrumbs.url ? (
                  <Link to={breadCrumbs.url}>{backContent}</Link>
                ) : (
                  <button onClick={breadCrumbs.onAction}>{backContent}</button>
                )}
              </div>
            )}

            {!!secondaryAction && <div>{secondaryAction}</div>}
          </div>

          {isString(title) ? <Header semiBold>{title}</Header> : title}

          {isString(subTitle) ? <SubHeader>{subTitle}</SubHeader> : subTitle}

          {!!headerImageURL && (
            <img
              className="header-image"
              src={headerImageURL}
              alt={typeof title === "string" ? title : "Header Image"}
            />
          )}
        </div>
      )}

      <div className="body">{children}</div>
    </Container>
  );
}

interface ContainerProps {
  fullWidth?: boolean;
}

const Container = styled.section<ContainerProps>`
  width: ${(props) => (props.fullWidth ? "100%" : "600px")};

  ${tw`flex flex-col max-w-full h-full my-0 mx-auto`};

  > .header {
    ${tw`mb-[24px]`};

    .breadcrumbs {
      &.has-spacing {
        ${tw`mb-[24px]`};
      }

      a,
      button {
        ${tw`flex items-center text-[1.6rem] text-black60`};

        ${tw`dark:text-white60`};

        svg {
          ${tw`w-[20px] h-[20px] mr-[2px] `};

          path {
            ${tw`text-black60 dark:text-white`};
          }
        }
      }
    }

    img.header-image {
      ${tw`w-full h-[213px] mobile:h-[200px] object-contain mb-[8px] rounded-[8px] mobile:object-cover`};
    }

    > h4 {
      ${tw`text-black60 dark:text-white60 font-normal mt-[8px]`};
    }
  }

  > .body {
    ${tw`w-full flex-grow`};

    ${tw`xl:pb-[24px] pb-[72px]`};

    /* padding-bottom: 72px; */

    ${media.mobile} {
      padding-bottom: ${DASHBOARD_BODY_PADDING_Y}px;
    }
  }
`;
