import Book, { ParsedBook } from "app/models/book";

export const parseBook = (b: Book): ParsedBook => {
  return {
    product: b.p,
    base: b.b,
    counter: b.c,
    category: b.cg,
    buy: b.bu,
    sell: b.sl,
    buy_price: b.bp,
    maximum_purchase: b.map,
    minimum_purchase: b.mip,
    sell_price: b.sp,
    maximum_sale: b.mas,
    minimum_sale: b.mis,
    base_decimals: b.bd,
    counter_decimals: b.cd,
  };
};
