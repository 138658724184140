import Account, { NewAccount } from "app/models/account";

import request from "../request";
import { BaseResponse, GenericResponse } from "../types";

export interface GetAccountParam {
  from_search: boolean;
}

export const getAccounts = () => {
  return request.get<GenericResponse<Account[]>>("/accounts");
};

export const getAccount = (currency: string, params?: GetAccountParam) => {
  return request.get<GenericResponse<Account>>(`/accounts/${currency}`, {
    params,
  });
};

export const getNewAccounts = () => {
  return request.get<GenericResponse<NewAccount[]>>(`/accounts/new`);
};

export const addNewAccount = (asset: string) => {
  return request.post<BaseResponse>(`/accounts/${asset}`);
};

export const getRecentSearch = () => {
  return request.get<GenericResponse<string[]>>("/accounts/recent_searches");
};

export const clearRecentSearch = () => {
  return request.delete<BaseResponse>(`/accounts/recent_searches`);
};

export const searchAccounts = (code: string) => {
  return request.get<GenericResponse<Account[]>>(
    `/accounts/${code}?from_search=${true}`
  );
};
