import { PropsWithChildren } from "react";

import { gotoLogin } from "app/lib/logout";
import useGetProfile from "app/hooks/useGetProfile";
import useRefreshUserSession from "app/hooks/useRefreshUserSession";
import useIsLoggedIn from "app/hooks/useAuth";
import LoaderContainer from "./ui/LoaderContainer";
import NetworkLoader from "./ui/NetworkLoader";
import useGetProfiles from "app/hooks/useGetProfiles";
import useGetVerificationStatus from "app/hooks/useGetVerificationStatus";
import { useActiveProfile } from "app/providers/ActiveProfileProvider";

export default function AuthGuard(props: PropsWithChildren) {
  const isLoggedIn = useIsLoggedIn();

  const activeProfile = useActiveProfile();

  if (!isLoggedIn) {
    gotoLogin();
  }

  const profileQuery = useGetProfile(isLoggedIn);

  const profilesQuery = useGetProfiles(isLoggedIn);

  const verificationStatusQuery = useGetVerificationStatus(
    isLoggedIn,
    activeProfile?.id as string
  );

  useRefreshUserSession();

  // const loading = isInitialLoading || (refreshingSession && !profile);

  const onRetry = () => {
    if (profileQuery.error) {
      profileQuery.refetch();
    }

    if (profilesQuery.error) {
      profilesQuery.getProfiles();
    }
    if (verificationStatusQuery.statusError) {
      verificationStatusQuery.getVerificationStatus();
    }
  };

  return (
    <>
      {profileQuery.isFetching ||
      profilesQuery.isFetching ||
      verificationStatusQuery.isFetchingStatus ? (
        <NetworkLoader page={false} />
      ) : null}

      <LoaderContainer
        screen
        loading={
          profileQuery.isLoading ||
          profilesQuery.isLoading ||
          verificationStatusQuery.isLoadingStatus
        }
        error={
          !!profileQuery.error ||
          !!profilesQuery.error ||
          !!verificationStatusQuery.statusError
        }
        onRetry={onRetry}
      >
        {props.children}
      </LoaderContainer>
    </>
  );
}
