import { useQuery } from "@tanstack/react-query";
import { getSecurity } from "app/api/profile";
import parseError from "app/lib/parseError";
import { AxiosError } from "axios";

const useGetSecurity = () => {
  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    ["security"],
    getSecurity
  );

  return {
    security: data?.data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getSecurity: refetch,
    isFetching,
    isLoading,
  };
};

export default useGetSecurity;
