import axios from "axios";

import { AUTH_URL } from "app/constants/variables";
import { ButagOwner } from "app/models/butagOwner";

import request from "../request";
import { BaseResponse } from "../types";
import {
  ForgotPasswordPayload,
  ForgotPasswordResponse,
  LoginConfirmPayload,
  LoginPayload,
  LoginResponse,
  ResetPasswordPayload,
  SignupPayload,
  SignupResponse,
} from "./types";

const defaultOption = {
  baseURL: AUTH_URL,
  headers: {
    noToken: true,
  },
};

export const verifyEmail = (email: string) => {
  return request.get(`verify/email/${email}`, defaultOption);
};

export const verifyReferralCode = (code: string) => {
  return request.post<{ valid: boolean }>(
    "verify/referral_code",
    { code },
    defaultOption
  );
};

export const verifyUsername = (username: string) => {
  return request.post<{ valid: boolean }>(
    "verify/username",
    { username },
    defaultOption
  );
};

export const login = (data: LoginPayload) => {
  return request.post<LoginResponse>("login", data, defaultOption);
};

export const loginConfirm = async (payload: LoginConfirmPayload) => {
  const { code, token } = payload;

  return request.post<LoginResponse>(
    "/login/confirm",
    { code },
    {
      ...defaultOption,
      headers: {
        Authorization: `Bearer ${token}`,
        noToken: true,
      },
    }
  );
};

export const resendAuthCode = async (token: string) => {
  return request.get<BaseResponse>("/login/resend/code", {
    ...defaultOption,
    headers: {
      Authorization: `Bearer ${token}`,
      noToken: true,
    },
  });
};

export const signUp = (data: SignupPayload) => {
  return request.post<SignupResponse>("register", data, defaultOption);
};

export const forgotPassword = (data: ForgotPasswordPayload) => {
  return request.post<ForgotPasswordResponse>(
    "password/forgot",
    data,
    defaultOption
  );
};

export const resetPassword = ({
  forgotPasswordToken,
  ...data
}: ResetPasswordPayload) => {
  return request.put<BaseResponse>("password/reset", data, {
    ...defaultOption,
    headers: {
      Authorization: `Bearer ${forgotPasswordToken}`,
      noToken: true,
    },
  });
};

export const refreshAccessToken = (refreshToken: string) => {
  return axios.get<LoginResponse>(
    `/login/token?refresh_token=${refreshToken}`,
    { baseURL: defaultOption.baseURL }
  );
};

export const getProfileByBTag = (tag: string) => {
  return request.get<ButagOwner>(`btag/${tag}`, defaultOption);
};
