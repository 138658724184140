import Card from "app/components/ui/Card";
import Header from "app/styles/Header";
import Text from "app/styles/Text";

import { ReactComponent as CaretRight } from "app/assets/icons/caret-right.svg";
import useGetPaymentMethods from "app/hooks/useGetPaymentMethods";
import LoaderContainer from "app/components/ui/LoaderContainer";
import { ConnectPaymentMethod } from "app/models/paymentMethod";

interface Props {
  onContinue: (m: ConnectPaymentMethod) => void;
  currency: string;
}

export default function SelectProvider(props: Props) {
  const { onContinue, currency } = props;

  const { methods, error, isLoading } = useGetPaymentMethods({
    category: "other_account",
    currency,
  });

  return (
    <LoaderContainer loading={isLoading} error={!!error} errorMessage={error}>
      <div>
        <Header tw="mb-[24px] text-[28px]">Select provider</Header>

        <Card>
          <div tw="flex flex-col gap-[16px] px-[20px] pt-[20px] pb-[16px]">
            {methods.map((m) => {
              return (
                <div
                  key={m.id}
                  tw="flex justify-between items-center not-last-of-type:border-b border-[#00000014] dark:border-[#FFFFFF14] not-last-of-type:pb-[16px]"
                  role="button"
                  onClick={() => onContinue(m)}
                >
                  <div tw="flex items-center gap-[10px]">
                    <img
                      src={m.logo}
                      alt={m.name}
                      tw="w-[40px] h-[40px] rounded-full object-cover"
                    />
                    <div>
                      <Text medium>{m.name}</Text>
                    </div>
                  </div>

                  <CaretRight tw="text-black32 dark:text-white32" />
                </div>
              );
            })}
          </div>
        </Card>
      </div>
    </LoaderContainer>
  );
}
