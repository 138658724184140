import { useState } from "react";
import tw, { styled } from "twin.macro";

import { ReactComponent as Trash } from "app/assets/icons/trash-outline.svg";
import { ReactComponent as Pen } from "app/assets/icons/pen-outline.svg";
import { ReactComponent as CaretRight } from "app/assets/icons/caret-right.svg";
import Card from "app/components/ui/Card";
import Text from "app/styles/Text";
import useModalState from "app/hooks/useModalState";
import useGoBack from "app/hooks/useGoBack";
import Page from "app/components/ui/Page";
import Header from "app/styles/Header";
import { ConnectPaymentAccount as PaymentAccount } from "app/models/paymentAccount";
import { countryMap } from "app/utils/mappers";

import DeleteAccountModal from "./DeleteAccountModal";
import EditUSDAccount from "./EditUSDAccount";

const steps = ["details", "edit"] as const;
type ActiveStep = typeof steps[number];

interface Props {
  account: PaymentAccount;
}

export default function USDAccountDetails(props: Props) {
  const { account } = props;

  const [activeStep, setActiveStep] = useState<ActiveStep>("details");
  const { isOpen, toggleIsOpen, closeModal } = useModalState();

  const previousPage = useGoBack();

  const goBack = () => {
    const activeIndex = steps.indexOf(activeStep);
    if (activeStep === "details") {
      return previousPage();
    }
    setActiveStep(steps[activeIndex - 1] as ActiveStep);
  };

  const onEdit = () => {
    setActiveStep("edit");
  };

  const getFieldValue = (matchingName: string) =>
    account.fields.find((s) => s.display_name === matchingName)?.value;

  const intermediarySwiftCode = getFieldValue("Intermediary Swift code");
  const intermediaryBankName = getFieldValue("Intermediary bank name");
  const intermediaryBankAddress = getFieldValue("Intermediary bank address");

  const intermediaryFieldsAreNotAllEmpty =
    !!intermediaryBankName ||
    !!intermediaryBankAddress ||
    !!intermediarySwiftCode;

  return (
    <Page breadCrumbs={{ label: "Back", onAction: goBack }}>
      {activeStep === "details" && (
        <>
          <div>
            <Header tw="text-[2.8rem] capitalize">
              {getFieldValue(
                account.payment_method.type === "personal"
                  ? "Recipient account Name"
                  : "Company account Name"
              )}
            </Header>
            <Text medium subdued>
              {account.payment_method.currency} Account
            </Text>
          </div>

          <DetailsSection>
            <Details>
              <Text semiBold>Account details</Text>

              <div>
                <Text subdued medium>
                  Country
                </Text>

                <Text medium>
                  {
                    countryMap[
                      account.payment_method.country as keyof typeof countryMap
                    ]
                  }
                </Text>
              </div>

              <div>
                <Text subdued medium>
                  Bank
                </Text>

                <Text medium>{getFieldValue("Bank Name")}</Text>
              </div>

              <div>
                <Text subdued medium>
                  Account number
                </Text>

                <Text medium tw="text-prime">
                  {getFieldValue("Account Number or IBAN")}
                </Text>
              </div>

              <div>
                <Text subdued medium tw="flex-1">
                  Bank address
                </Text>

                <Text medium tw="text-right flex-1">
                  {getFieldValue("Bank Address")}
                </Text>
              </div>

              <div>
                <Text subdued medium tw="flex-1">
                  Account type
                </Text>

                <Text medium tw="text-right flex-1 capitalize">
                  {account.payment_method.type}
                </Text>
              </div>
            </Details>

            <Details>
              <Text semiBold>Beneficiary details</Text>

              <div>
                <Text subdued medium>
                  Account name
                </Text>

                <Text medium tw="capitalize">
                  {getFieldValue(
                    account.payment_method.type === "personal"
                      ? "Recipient account Name"
                      : "Company account Name"
                  )}
                </Text>
              </div>

              <div>
                <Text subdued medium tw="flex-1">
                  Recipient address
                </Text>

                <Text medium tw="text-right flex-1">
                  {getFieldValue(
                    account.payment_method.type === "personal"
                      ? "Recipient address"
                      : "Company address"
                  )}
                </Text>
              </div>
            </Details>

            {intermediaryFieldsAreNotAllEmpty && (
              <Details>
                <Text semiBold>Intermediary details</Text>

                {!!intermediaryBankName && (
                  <div>
                    <Text subdued medium>
                      Bank name
                    </Text>

                    <Text medium>{intermediaryBankName}</Text>
                  </div>
                )}

                {!!intermediaryBankAddress && (
                  <div>
                    <Text subdued medium tw="flex-1">
                      Bank address
                    </Text>

                    <Text medium tw="text-right flex-1">
                      {intermediaryBankAddress}
                    </Text>
                  </div>
                )}

                {!!intermediarySwiftCode && (
                  <div>
                    <Text subdued medium tw="flex-1">
                      Swift/Routing number
                    </Text>

                    <Text medium tw="text-right flex-1">
                      {intermediarySwiftCode}
                    </Text>
                  </div>
                )}
              </Details>
            )}
          </DetailsSection>

          <Card tw="px-[20px] py-[8px] mt-[24px]">
            <div
              tw="flex items-center py-[16px] justify-between gap-[16px]"
              role="button"
              onClick={onEdit}
            >
              <Pen width={24} height={24} />

              <Text tw="flex-1" medium>
                Edit account details
              </Text>

              <CaretRight tw="dark:fill-current text-white opacity-[0.32]" />
            </div>

            <div
              tw="flex items-center py-[16px] justify-between gap-[16px]"
              role="button"
              onClick={toggleIsOpen}
            >
              <Trash width={24} height={24} color="#C00F00" />

              <Text tw="flex-1" medium>
                Delete account
              </Text>

              <CaretRight tw="dark:fill-current text-white opacity-[0.32]" />
            </div>
          </Card>

          <DeleteAccountModal
            accountId={account.id}
            isOpen={isOpen}
            onClose={closeModal}
          />
        </>
      )}

      {activeStep === "edit" && (
        <EditUSDAccount account={account} onContinue={goBack} />
      )}
    </Page>
  );
}

const DetailsSection = styled(Card)`
  ${tw`p-[20px] mt-[24px]`}

  > div {
    ${tw`not-last-of-type:border-b border-[#00000014] dark:border-[#FFFFFF14] not-last-of-type:pb-[16px] not-first-of-type:mt-[16px]`}
  }
`;

const Details = styled.div`
  ${tw`flex flex-col gap-[16px]`}

  > div {
    ${tw`flex justify-between items-baseline`}
  }
`;
