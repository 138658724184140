import tw, { styled } from "twin.macro";
import { ReactNode } from "react";

import { ReactComponent as Profile } from "app/assets/icons/profile-outline-dark.svg";
import { ReactComponent as Briefcase } from "app/assets/icons/briefcase.svg";
import { ReactComponent as CaretRight } from "app/assets/icons/caret-right.svg";
import Card from "app/components/ui/Card";
import Header from "app/styles/Header";
import Text from "app/styles/Text";
import useGetPaymentMethods from "app/hooks/useGetPaymentMethods";
import LoaderContainer from "app/components/ui/LoaderContainer";
import { ConnectPaymentMethod } from "app/models/paymentMethod";

interface Props {
  onSelectType: (acc: ConnectPaymentMethod) => void;
  accountMethod: ConnectPaymentMethod;
}

const iconsMap: Record<ConnectPaymentMethod["type"], ReactNode> = {
  personal: <Profile height={16} width={16} />,
  business: <Briefcase height={16} width={16} />,
};

export default function SelectAccountType(props: Props) {
  const { onSelectType, accountMethod } = props;

  const {
    methods: accountTypes,
    isLoading,
    error,
    refetchPaymentMethods,
  } = useGetPaymentMethods({
    category: "bank_account",
    country: accountMethod.country,
    currency: accountMethod.currency,
  });

  return (
    <LoaderContainer
      loading={isLoading}
      error={!!error}
      onRetry={refetchPaymentMethods}
    >
      <Header>Select account type</Header>

      <Container>
        {accountTypes &&
          accountTypes.map((accountType) => (
            <Tile
              key={accountType.id}
              role="button"
              onClick={() => onSelectType(accountType)}
            >
              <div className="icon">{iconsMap[accountType.type]}</div>

              <Text tw="flex-1 text-black95 dark:text-white95" medium>
                {accountType.name}
              </Text>

              <CaretRight tw="text-black32 dark:text-white60" width={9} />
            </Tile>
          ))}
      </Container>
    </LoaderContainer>
  );
}

const Container = styled(Card)`
  ${tw`flex flex-col gap-[8px] py-[12px] px-[20px] mt-[24px]`};

  > div:not(:last-child) {
    ${tw`border-b border-[#00000014] dark:border-[#FFFFFF14] pb-[15px]`}
  }
`;

const Tile = styled.div`
  ${tw`flex items-center gap-[16px] py-[8px]`}

  .icon {
    ${tw`w-[40px] h-[40px] bg-greyScale05 dark:bg-[#C4C4C4] flex justify-center items-center flex-shrink-0 rounded-full`}
  }
`;
