import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { getBanks } from "app/api/banks";

const useGetAllBanks = () => {
  const { isLoading, data, isFetching, error, refetch } = useQuery(
    ["banks"],
    () => getBanks()
  );

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<any>) as string) : undefined,
    getBanks: refetch,
    banks: data?.data.data,
  };
};

export default useGetAllBanks;
