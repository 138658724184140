import tw, { styled } from "twin.macro";
import { useNavigate } from "react-router-dom";

import Card from "app/components/ui/Card";
import Text from "app/styles/Text";
import { ReactComponent as Add } from "app/assets/icons/add.svg";
import { ReactComponent as CaretRight } from "app/assets/icons/caret-right.svg";
import { BANK_IMG_BASE_URL, FIAT_CURRENCY_ICON } from "app/constants/variables";
import EmptyState from "./EmptyState";
import useGetPaymentAccounts from "app/hooks/useGetPaymentAccounts";
import LoaderContainer from "app/components/ui/LoaderContainer";

export default function PaymentAccounts() {
  const navigate = useNavigate();

  const { isLoading, accounts, refetch, error } = useGetPaymentAccounts({
    category: "bank_account",
  });

  const bankAccounts = accounts?.data;

  return (
    <LoaderContainer
      loading={isLoading}
      error={!!error}
      onRetry={refetch}
      errorMessage={error}
    >
      {bankAccounts?.length ? (
        <>
          <Card tw="bg-transparent">
            <div
              tw="flex p-[16px] justify-between items-center hover:bg-[rgba(0, 0, 0, 0.05)] dark:hover:bg-black60"
              role="button"
              onClick={() => navigate("add")}
            >
              <div tw="flex items-center gap-[10px]">
                <div tw="flex items-center justify-center w-16 h-16 rounded-full bg-[#E6F2ED]">
                  <Add tw="text-[#12A633]" />
                </div>

                <Text medium>Add a new bank account</Text>
              </div>

              <CaretRight tw="text-black32 dark:text-white32" />
            </div>
          </Card>

          <Card tw="mt-[16px] px-[20px]">
            <div tw="flex flex-col py-[16px] gap-[16px]">
              {bankAccounts.map((account) => {
                const accountNumber = account.fields.find((f) =>
                  ["account_number"].includes(f.key)
                )?.value;

                const accountName = account.fields.find((f) =>
                  [
                    "recipient_account_name",
                    "account_name",
                    "company_account_name",
                  ].includes(f.key)
                )?.value;

                const bankName = account.fields.find(
                  (s) => s.display_name === "Bank Name"
                )?.value;

                return (
                  <AccountTile
                    key={account.id}
                    role="button"
                    onClick={() => navigate(account.id)}
                  >
                    <div tw="w-full flex items-center gap-[16px]">
                      <div tw="relative h-[40px] w-[40px] flex-shrink-0">
                        <img
                          src={BANK_IMG_BASE_URL}
                          alt={account.payment_method.currency}
                          tw=" w-[40px] h-[40px] self-center rounded-full object-contain"
                        />
                        <img
                          src={`${FIAT_CURRENCY_ICON}/${account.payment_method.currency}`}
                          tw="absolute right-0 bottom-0"
                          alt={account.payment_method.currency}
                          width={16}
                          height={16}
                        />
                      </div>

                      <div tw="flex-1 flex items-center gap-[40px] justify-between">
                        <div tw="break-words flex-1">
                          <Text medium>{accountName}</Text>
                          <Text tw="text-black32 dark:text-white32 capitalize">
                            {accountNumber} • {bankName}
                          </Text>
                        </div>

                        <CaretRight
                          tw="text-black32 dark:text-white32 flex-shrink-0"
                          role="button"
                        />
                      </div>
                    </div>
                  </AccountTile>
                );
              })}
            </div>
          </Card>
        </>
      ) : (
        <EmptyState />
      )}
    </LoaderContainer>
  );
}

const AccountTile = styled.div`
  ${tw`flex justify-between items-center not-last-of-type:border-b border-[#00000014] dark:border-[#FFFFFF14] not-last-of-type:pb-[16px]`}
`;
