import * as React from "react";
import * as Sentry from "@sentry/react";
import {
  HistoryRouterProps,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";

import ErrorWidget from "./components/ui/ErrorWidget";
import history from "./lib/history";
import AppThemeProvider from "./providers/AppThemeProvider";
import QueryProvider from "./providers/QueryProvider";
import ToastProvider from "./providers/ToastProvider";
import TokenProvider from "./providers/TokenProvider";
import Routes from "./routes";
import ProfileProvider from "./providers/ProfileProvider";
import LoaderContainer from "./components/ui/LoaderContainer";

function App() {
  return (
    <Sentry.ErrorBoundary
      fallback={ErrorWidget}
      beforeCapture={(scope) => {
        scope.setTag("location", "app");
      }}
    >
      <TokenProvider>
        <QueryProvider>
          <AppThemeProvider>
            <ToastProvider>
              <ProfileProvider>
                <HistoryRouter
                  history={history as unknown as HistoryRouterProps["history"]}
                >
                  <React.Suspense fallback={<LoaderContainer screen loading />}>
                    <Routes />
                  </React.Suspense>
                </HistoryRouter>
              </ProfileProvider>
            </ToastProvider>
          </AppThemeProvider>
        </QueryProvider>
      </TokenProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
