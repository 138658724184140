import { useQuery } from "@tanstack/react-query";

import AnncounementService from "app/api/announcements";

export default function useListAnnouncements(country?: string) {
  const { data, status, error, refetch, isFetching, isLoading, fetchStatus } =
    useQuery(["announcements"], () =>
      AnncounementService.listAnouncements(country)
    );

  return {
    data,
    status,
    error,
    refetch,
    isFetching,
    isLoading,
    fetchStatus,
  };
}
