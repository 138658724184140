import * as Yup from "yup";

type GetSchemaProps = {
  areIntermediaryFieldsRequired: boolean;
  isBusinessAccount: boolean;
};
export const getSchema = ({
  areIntermediaryFieldsRequired,
  isBusinessAccount,
}: GetSchemaProps) => {
  const schema = Yup.object().shape({
    bank_name: Yup.string()
      .required("Bank name is a required field")
      .min(2, "Bank name is too short")
      .max(50),
    bank_address: Yup.string()
      .required("Bank address is a required field")
      .min(5, "Bank address is too short")
      .max(150),
    [isBusinessAccount ? "company_address" : "recipient_address"]: Yup.string()
      .required(
        `${isBusinessAccount ? "Company" : "Recipient"} address is required`
      )
      .min(2, "Address is too short")
      .max(150),
    [isBusinessAccount ? "company_account_name" : "recipient_account_name"]:
      Yup.string()
        .required(
          `${
            isBusinessAccount ? "Company" : "Recipient"
          } account name is required`
        )
        .min(2, "Account name is too short")
        .max(50),
    intermediary_swift_code: areIntermediaryFieldsRequired
      ? Yup.string()
          .matches(
            /^[A-Za-z0-9]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$|^[0-9]{9}$/,
            "Wrong format"
          )
          .required("Intermediary swift code is required")
      : Yup.string().matches(/^.{0,50}$/, "Wrong format"),
    intermediary_bank_name: areIntermediaryFieldsRequired
      ? Yup.string()
          .matches(/^.{0,50}$/, "Intermediary bank name is too short")
          .required("Intermediary bank name is required")
      : Yup.string().matches(/^.{0,50}$/),
    intermediary_bank_address: areIntermediaryFieldsRequired
      ? Yup.string()
          .matches(/^.{0,200}$/)
          .required("Intermediary bank address is required")
      : Yup.string().matches(/^.{0,200}$/),
    account_number: Yup.string()
      .required("Account number is required")
      .matches(/^[A-Za-z0-9]{1,20}$/),
    swift_code: Yup.string()
      .required("Swift code is required")
      .matches(
        /^[A-Za-z0-9]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$|^[0-9]{9}$/,
        "Wrong format"
      ),
  });

  return schema;
};
