import * as React from "react";

import Profile from "app/models/profile";
import storage from "app/lib/storage";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { PROFILE_STORAGE_KEY } from "app/constants/variables";

export const ProfileContext =
  React.createContext<Profile | undefined>(undefined);
ProfileContext.displayName = "ProfileContext";

export const SetProfileContext = React.createContext<
  React.Dispatch<React.SetStateAction<Profile | undefined>>
>((p) => {});
SetProfileContext.displayName = "SetProfileContext";

export default function ProfileProvider(props: React.PropsWithChildren<{}>) {
  const [profile, setProfile] = React.useState<Profile | undefined>(
    () => storage.get(PROFILE_STORAGE_KEY) ?? undefined
  );

  useUpdatedEffect(() => {
    if (profile) {
      storage.set(PROFILE_STORAGE_KEY, profile);
    }
  }, [profile]);

  return (
    <ProfileContext.Provider value={profile}>
      <SetProfileContext.Provider value={setProfile}>
        {props.children}
      </SetProfileContext.Provider>
    </ProfileContext.Provider>
  );
}

export const useProfile = () => {
  const profile = React.useContext(ProfileContext);

  return profile;
};

export const useSetProfile = () => {
  const setProfile = React.useContext(SetProfileContext);

  return setProfile;
};
