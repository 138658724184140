import * as React from "react";

import { APP_THEME_STORAGE_KEY } from "app/constants/variables";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import useUpdatedLayoutEffect from "app/hooks/useUpdatedLayoutEffect";

export type AppTheme = "dark" | "light";

const ThemeStateContext = React.createContext<AppTheme>("dark");
ThemeStateContext.displayName = "ThemeStateContext";

const ThemeDispatchContext = React.createContext<
  React.Dispatch<React.SetStateAction<AppTheme>>
>(() => {});
ThemeDispatchContext.displayName = "ThemeDispatchContext";

const colorSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)");

export default function AppThemeProvider(props: React.PropsWithChildren<any>) {
  const [theme, setTheme] = React.useState<AppTheme>(() => {
    const isDark =
      localStorage.getItem(APP_THEME_STORAGE_KEY) === "dark" ||
      (!(APP_THEME_STORAGE_KEY in localStorage) && colorSchemeQuery.matches);

    return isDark ? "dark" : "light";
  });

  React.useEffect(() => {
    const onColorSchemeChange = (ev: MediaQueryListEvent) => {
      setTheme(ev.matches ? "dark" : "light");
    };

    if (APP_THEME_STORAGE_KEY in localStorage) return;

    colorSchemeQuery.addEventListener("change", onColorSchemeChange);

    return () => {
      colorSchemeQuery.removeEventListener("change", onColorSchemeChange);
    };
  }, []);

  useUpdatedLayoutEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  useUpdatedEffect(() => {
    //if (!(APP_THEME_STORAGE_KEY in localStorage)) return;
    
    localStorage.setItem(APP_THEME_STORAGE_KEY, theme);
  }, [theme]);

  return (
    <ThemeStateContext.Provider value={theme}>
      <ThemeDispatchContext.Provider value={setTheme}>
        {/* <button onClick={() => setTheme(theme === "light" ? "dark" : "light")}>
          change theme
        </button> */}
        {props.children}
      </ThemeDispatchContext.Provider>
    </ThemeStateContext.Provider>
  );
}

export function useAppTheme() {
  const theme = React.useContext(ThemeStateContext);

  return theme;
}

export function useSetAppTheme() {
  const setTheme = React.useContext(ThemeDispatchContext);

  return setTheme;
}
