import { parseToken } from "app/lib/encryption";

import Announcement from "./components/Announcement";

export default function Announcements() {
  const show = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    const tokenData = parseToken();
    const loggedInTime = parseInt(tokenData?.iat as string, 10);

    const elapsedTime = currentTime - loggedInTime;
    if (elapsedTime < 5) {
      return true;
    }
    return false;
  };

  if (!show()) return null;

  return <Announcement />;
}
