import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useIntercomApp } from "app/providers/IntercomAppProvider";
import useInitializeIntercom from "./useInitializeIntercom";

const QUERY_KEY = "open-intercom";

const useIntercomActions = () => {
  const { isBooted } = useInitializeIntercom();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showNewMessage } = useIntercomApp();

  const intercomMessage = searchParams.get(QUERY_KEY);

  const clearIntercomQuery = useCallback(() => {
    setSearchParams((p) => {
      p.delete(QUERY_KEY);
      return p;
    });
  }, [setSearchParams]);

  useEffect(() => {
    if (!!intercomMessage && isBooted) {
      showNewMessage(intercomMessage);

      clearIntercomQuery();
    }
  }, [clearIntercomQuery, intercomMessage, showNewMessage, isBooted]);
};

export default useIntercomActions;
