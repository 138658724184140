import styled from "@emotion/styled";
import tw from "twin.macro";

import { ReactComponent as Light } from "app/assets/icons/light.svg";
import { ReactComponent as Dark } from "app/assets/icons/dark.svg";

import { useAppTheme, useSetAppTheme } from "app/providers/AppThemeProvider";

export default function DashboardHeaderThemeButton() {
  const theme = useAppTheme();
  const setTheme = useSetAppTheme();

  const toggleTheme = () => setTheme((t) => (t === "dark" ? "light" : "dark"));

  const themeIcon = theme === "dark" ? <Light /> : <Dark />;

  return (
    <ThemeButton aria-label="Change theme" type="button" onClick={toggleTheme}>
      {themeIcon}
    </ThemeButton>
  );
}

const ThemeButton = styled.button`
  ${tw`w-[36px] h-[36px] flex items-center justify-center`};

  svg {
    ${tw`w-[20px] h-[20px]`};
  }
`;
