import { useQuery } from "@tanstack/react-query";
import { getAllBusinessVerificationSections } from "app/api/business_verification";
import parseError from "app/lib/parseError";
import { useActiveProfile } from "app/providers/ActiveProfileProvider";
import { AxiosError } from "axios";

const useGetAllBusinessVerificationSections = () => {
  const activeProfile = useActiveProfile();
  const {
    isInitialLoading: isLoading,
    data,
    isFetching,
    error,
    refetch,
  } = useQuery({
    queryFn: getAllBusinessVerificationSections,
    queryKey: ["get-business-verification-sections"],
    enabled: activeProfile?.profile_type === "business",
  });

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<any>) as string) : undefined,
    getSections: refetch,
    sections: data?.data.data.sections,
  };
};

export default useGetAllBusinessVerificationSections;
