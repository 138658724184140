import { Bank } from "app/models/bank";

import request from "../request";
import { GenericResponse } from "../types";
import { VerifyBankDetailsParams, VerifyBankDetailsResponse } from "./types";

export const getBanks = () => {
  return request.get<GenericResponse<Bank[]>>(`/payment_accounts/bank_list`);
};

export const verifyBankDetails = (params: VerifyBankDetailsParams) => {
  return request.get<VerifyBankDetailsResponse>(
    `/payment_accounts/verify_bank`,
    { params }
  );
};
