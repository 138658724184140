import { useMemo, useState } from "react";
import tw, { styled } from "twin.macro";
import { Combobox, Transition } from "@headlessui/react";

import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg";
import { ReactComponent as CaretDown } from "app/assets/icons/caret-down-alt.svg";
import { ReactComponent as Selected } from "app/assets/icons/check-outlined.svg";
import Text from "app/styles/Text";
import SelectOption from "app/models/selectOption";

interface Props {
  label?: string;
  placeholder: string;
  value?: string;
  options?: SelectOption[];
  onChange?: (i: SelectOption) => void;
}

export default function AutoComplete(props: Props) {
  const { value, onChange, options, label, placeholder } = props;

  const selected = options?.find((o) => o.value === value);

  const [query, setQuery] = useState("");

  const handleQueryFieldValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.value.trim()?.length) {
      onChange && onChange({ value: "", label: "" });
    }
    setQuery(e.target.value);
  };

  const items = useMemo(() => {
    return options?.filter(
      (item) =>
        item.value.toLowerCase().includes(query?.toLowerCase().trim()) ||
        item.label.toLowerCase().includes(query?.toLowerCase().trim())
    );
  }, [options, query]);

  return (
    <div>
      {/* @ts-ignore */}
      <Combobox value={selected ?? ""} onChange={onChange}>
        <div css={tw`relative`}>
          <Text medium css={tw`mb-3`}>
            {label}
          </Text>

          {/* @ts-ignore */}
          <Combobox.Button as={SearchButton}>
            <InputContainer tw="flex justify-between">
              <Text subdued={!selected?.value}>
                {selected?.label || placeholder}
              </Text>

              <CaretDown width={11} tw="flex-shrink-0" />
            </InputContainer>
          </Combobox.Button>

          {/* @ts-ignore */}
          <Transition
            as="div"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery(selected?.label as string)}
          >
            {/* @ts-ignore */}
            <Combobox.Options as={Dropdown}>
              <div tw="px-[20px] mb-[8px]">
                <InputContainer>
                  {/* @ts-ignore */}
                  <Combobox.Button as={SearchButton}>
                    <SearchIcon />
                  </Combobox.Button>
                  {/* @ts-ignore */}
                  <Combobox.Input
                    placeholder={placeholder}
                    value={query}
                    onChange={handleQueryFieldValueChange}
                    css={tw`w-full bg-transparent outline-none text-[1.8rem] placeholder:text-black32 dark:placeholder:text-white32 dark:text-white95`}
                  />

                  {!!query?.length && (
                    <button
                      tw="text-[1.6rem] font-semibold"
                      onClick={() => setQuery("")}
                    >
                      Clear
                    </button>
                  )}
                </InputContainer>
              </div>

              {query &&
                items?.map((item) => (
                  <Combobox.Option key={item.value} value={item}>
                    {({ selected, active }) => (
                      <>
                        <Option
                          key={item.value}
                          onClick={() => {
                            setQuery(item.label);
                          }}
                        >
                          <div className="container">
                            <div tw="pt-[8px]">
                              <Text medium>{item.label}</Text>
                            </div>
                          </div>

                          {selected && <Selected className="selected-icon" />}
                        </Option>
                      </>
                    )}
                  </Combobox.Option>
                ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}

const InputContainer = styled.div`
  ${tw`w-full flex items-center gap-[9px] px-[16px] py-[10px] rounded-[4px] border border-[#0000001F] focus-within:border-black transition-colors`};

  ${tw`dark:border-white30 dark:focus-within:border-white`};
`;

const SearchButton = styled.div`
  svg {
    path {
      ${tw`dark:fill-transparent`};
    }
  }
`;

const Dropdown = styled.div`
  width: 100%;
  z-index: 999;
  position: absolute;
  top: 74px;

  li {
    list-style-type: none;
  }

  ${tw`pt-[20px] dark:bg-[#000] dark:border-[rgba(255, 255, 255, 0.12)] max-h-[286px] overflow-scroll`};
  ${tw` rounded-[8px] border border-transparent bg-white z-10 shadow-[0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.08)] mt-[10px]`};
`;

const Option = styled.div`
  ${tw`flex justify-between items-center px-[20px] py-[5px] hover:bg-greyScale05 dark:hover:bg-[#0D0F12] cursor-pointer`};

  .container {
    ${tw`flex h-[40px] gap-[14px]`}
  }

  .selected-icon {
    ${tw`w-[24px] h-[24px] flex-shrink-0 text-primary90 dark:text-prime`};
    path {
      ${tw`fill-current`};
    }
  }

  img {
    ${tw`w-16 h-16 self-center rounded-full object-contain`};
  }
`;
