import { BUSHA_ENV } from "app/constants/variables";
import { getDeviceId } from "./device";

let device_id: string;
getDeviceId().then((s) => (device_id = s));

export const dispatchGTMEvent = async (event: string, data: any = {}) => {
  if (BUSHA_ENV !== "production") return;

  const gtag = (window as any).dataLayer;

  if (gtag) {
    gtag.push({
      event,
      ...data,
      device_id: device_id ?? (await getDeviceId()),
    });
  }
};
