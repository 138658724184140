import { useQuery } from "@tanstack/react-query";
import { getProfileByBTag } from "app/api/auth";
import parseError from "app/lib/parseError";
import { useSetPaymentLinksRecipient } from "app/providers/PaymentLinksRecipientProvider";
import { AxiosError } from "axios";

interface Props {
  butag: string;
  relativeToPaymentLinks?: boolean;
}

const useGetButagOwner = (props: Props) => {
  const { butag, relativeToPaymentLinks } = props;

  const setPaymentLinksRecipient = useSetPaymentLinksRecipient();

  const { isLoading, error, refetch, data } = useQuery(
    ["butag", butag, relativeToPaymentLinks],
    () => getProfileByBTag(butag),
    {
      onSuccess: (d) => {
        if (relativeToPaymentLinks) {
          setPaymentLinksRecipient(d.data);
        }
      },
    }
  );

  return {
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getOwner: refetch,
    owner: data?.data,
  };
};

export default useGetButagOwner;
