import { GenericErrorResponse } from "app/api/types";
import { AxiosError } from "axios";

interface OptionsProps {
  useErrorResponse?: boolean;
}

const parseError = <T extends GenericErrorResponse>(
  error: AxiosError<T | GenericErrorResponse>,
  options: OptionsProps = { useErrorResponse: false }
) => {
  const DEFAULT_MESSAGE = "Error Occured";

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const errorObj = error.response.data;

    if (!errorObj) return DEFAULT_MESSAGE;

    if (options.useErrorResponse) return errorObj.error;

    if (errorObj.message) return errorObj.message;

    return errorObj.error ? errorObj.error.message : DEFAULT_MESSAGE;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // console.log(error.request);
    return DEFAULT_MESSAGE;
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log("Error", error.message);
    return DEFAULT_MESSAGE;
  }
};

export default parseError;
