import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import parseError from "app/lib/parseError";
import { updateUserPaymentAccount } from "app/api/payment_accounts";
import { CreateUserPaymentAccountPayload } from "app/api/payment_accounts/types";
import { useToast } from "app/providers/ToastProvider";
import { BaseResponse } from "app/api/types";

export default function useUpdatePaymentAccount(id: string) {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const handleError = (error: AxiosError<any>) => {
    const errorMessage = parseError(error) as string;
    showToast("", errorMessage, "error");
  };

  const handleSuccess = (res: AxiosResponse<BaseResponse>) => {
    showToast("", res.data.message || "", "success");
    queryClient.invalidateQueries(["payment_account"]);
    queryClient.invalidateQueries(["payment_accounts"]);
  };

  const { isLoading: isUpdatingPaymentAccount, mutate: updateAccount } =
    useMutation(
      (payload: CreateUserPaymentAccountPayload) =>
        updateUserPaymentAccount(id, payload),
      { onError: handleError, onSuccess: handleSuccess }
    );

  return {
    isUpdatingPaymentAccount,
    updateAccount,
  };
}
