import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { getUserPaymentAccount } from "app/api/payment_accounts";
import parseError from "app/lib/parseError";

export default function useGetPaymentAccount(id: string) {
  const { data, isLoading, fetchStatus, error, refetch, isFetching } = useQuery(
    ["payment_account", id],
    () => getUserPaymentAccount(id),
    {
      enabled: !!id,
      staleTime: 1000 * 60 * 10, // 10 minutes
    }
  );

  return {
    isLoading: isLoading && fetchStatus !== "idle",
    isFetching,
    error: error ? (parseError(error as AxiosError<any>) as string) : undefined,
    account: data?.data.data,
    refetch,
  };
}
