import { AxiosError } from "axios";
import { useInfiniteQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { getCurrencies } from "app/api/currencies";
import { GetCurrenciesParam } from "app/api/currencies/types";

interface Props {
  params: GetCurrenciesParam;
}

const useGetInfiniteCurrencies = (props: Props) => {
  const { params } = props;

  const {
    isLoading,
    isFetching,
    error,
    refetch,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data,
  } = useInfiniteQuery(
    ["currencies", ...Object.values(params)],
    ({ pageParam }) => getCurrencies({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage, _) =>
        lastPage.data.pagination.page < lastPage.data.pagination.total_pages
          ? lastPage.data.pagination.page + 1
          : undefined,
    }
  );

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<any>) as string) : undefined,
    currencies: data?.pages.map((page) => page.data.data).flat(),
    getCurrencies: refetch,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};

export default useGetInfiniteCurrencies;
