import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import parseError from "app/lib/parseError";
import { getRecentSearch } from "app/api/accounts";

export default function useGetRecentSearches() {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["recent-searches"],
    getRecentSearch
  );

  return {
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getRecentSearch: refetch,
    isFetching,
    isLoading,
  };
}
