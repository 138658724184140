import * as React from "react";
import { INTERCOM_APP_ID } from "app/constants/variables";

import { IntercomProvider, useIntercom } from "react-use-intercom";

const IntercomAppProvider = (props: React.PropsWithChildren<any>) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID || ""}>
      {props.children}
    </IntercomProvider>
  );
};

export const useIntercomApp = () => {
  const { showNewMessage } = useIntercom();

  const reportTransaction = (reference: string) => {
    showNewMessage(
      `I need help with my transaction with reference: ${reference}`
    );
  };

  return {
    reportTransaction,
    showNewMessage,
  };
};

export default IntercomAppProvider;
