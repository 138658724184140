import { useQuery } from "@tanstack/react-query";

import { refreshAccessToken } from "app/api/auth";
import { REFERESH_ACCESS_TOKEN_QUERY } from "app/constants/commonQueries";
import { TOKEN_STORAGE_KEY } from "app/constants/variables";
import { gotoSignout } from "app/lib/logout";
import storage from "app/lib/storage";
import { useSetToken, useToken } from "app/providers/TokenProvider";

export default function useRefreshUserSession() {
  const tokens = useToken();

  const refresh_token = tokens?.refresh_token;

  const setAppTokens = useSetToken();

  const { isInitialLoading, isFetching } = useQuery({
    enabled: false,
    retry: false,
    queryKey: [REFERESH_ACCESS_TOKEN_QUERY],
    staleTime: 1000 * 60 * 2,
    queryFn: () => {
      if (!refresh_token) {
        throw new Error("No refresh token");
      }

      return refreshAccessToken(refresh_token);
    },
    onSuccess: (response) => {
      // console.log("called!!!", response);
      const access_token = response.data.access_token;

      const tokens = {
        access_token,
        refresh_token,
      };

      storage.set(TOKEN_STORAGE_KEY, tokens);

      setAppTokens(tokens);
    },
    onError: () => {
      // console.log("signnout");
      gotoSignout();
    },
  });

  return {
    isLoading: isInitialLoading || isFetching,
  };
}
