import * as React from "react";
import useSocket from "app/hooks/useSocket";
import useGetAccounts from "app/hooks/useGetAccounts";
import useBooks from "app/hooks/useBooks";
import useCurrencies from "app/hooks/useCurrencies";
import useIntercomActions from "app/hooks/useIntercomData";

export default function DashboardHooksCall(props: React.PropsWithChildren) {
  const { children } = props;

  useIntercomActions();
  useGetAccounts();
  useBooks();
  useCurrencies();
  useSocket();

  return <>{children}</>;
}
