import * as React from "react";
import {
  USER_BALANCE_STATE_KEY,
  USER_EMPTY_WALLETS_STATE_KEY,
} from "app/constants/variables";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import storage from "app/lib/storage";
import { MetaBalance } from "app/models/balance";

export type BalanceVisibility = "hidden" | "visible";

export const BalanceVisibilityContext = React.createContext<MetaBalance>({
  visibility: "visible",
  emptyWallets: "visible",
});
BalanceVisibilityContext.displayName = "BalanceVisibilityContext";

const SetBalanceVisibilityContext = React.createContext<
  React.Dispatch<React.SetStateAction<MetaBalance>>
>(() => {});
SetBalanceVisibilityContext.displayName = "SetBalanceVisibilityContext";

export default function BalanceVisibilityProvider(
  props: React.PropsWithChildren<any>
) {
  const [state, setState] = React.useState<MetaBalance>(() => ({
    visibility: storage.get(USER_BALANCE_STATE_KEY) ?? "visible",
    emptyWallets: storage.get(USER_EMPTY_WALLETS_STATE_KEY) ?? "visible",
  }));

  useUpdatedEffect(() => {
    if (state) {
      storage.set(USER_BALANCE_STATE_KEY, state.visibility);
      storage.set(USER_EMPTY_WALLETS_STATE_KEY, state.emptyWallets);
    }
  }, [state]);

  return (
    <SetBalanceVisibilityContext.Provider value={setState}>
      <BalanceVisibilityContext.Provider value={state}>
        {props.children}
      </BalanceVisibilityContext.Provider>
    </SetBalanceVisibilityContext.Provider>
  );
}

export function useBalanceState() {
  return React.useContext(BalanceVisibilityContext);
}

export function useSetBalanceState() {
  return React.useContext(SetBalanceVisibilityContext);
}
