import { AUTH_URL } from "app/constants/variables";
import { Country, SupportedCountry } from "app/models/country";

import request from "../request";

const defaultOption = { baseURL: AUTH_URL };

export const getSupportedCountries = () => {
  return request.get<SupportedCountry[]>(`/countries`, defaultOption);
};

export const getAllCountries = () => {
  return request.get<Country[]>(`/countries/all`, defaultOption);
};
