import clsx from "clsx";
import tw, { styled } from "twin.macro";

interface Props {
  frames: number;
  activeFrame: number;
}
export default function StepIndicator(props: Props) {
  const { frames, activeFrame } = props;

  return (
    <Container>
      {Array(frames)
        .fill("")
        .map((_, i) => (
          <span
            key={i}
            className={clsx({
              completed: i + 1 < activeFrame,
              active: i + 1 === activeFrame,
            })}
          />
        ))}
    </Container>
  );
}

const Container = styled.div`
  ${tw`inline-flex gap-[4px]`};

  span {
    ${tw`w-[6px] h-[6px] bg-greyScale30 rounded-[16px] transition-[background-color,width]`};
    ${tw`dark:bg-greyScale30`};

    &.completed,
    &.active {
      ${tw`bg-primary70 dark:bg-primary70`};
    }

    &.active {
      ${tw`w-[18px]`};
    }
  }
`;
