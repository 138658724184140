import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { searchCurrencies } from "app/api/currencies";
import { SearchCurrenciesParam } from "app/api/currencies/types";
import { useProfile } from "app/providers/ProfileProvider";
import { useMemo } from "react";

const useSearchCurrencies = (params: Partial<SearchCurrenciesParam>) => {
  const profile = useProfile();

  const currency = useMemo(
    () =>
      profile?.display_currency.toLowerCase() === "usd"
        ? "usdt"
        : profile?.display_currency,
    [profile?.display_currency]
  );

  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    ["search-currencies", { ...params }],
    () =>
      searchCurrencies({
        counter: currency ?? "",
        limit: params.limit,
        page: params.page ?? 1,
        filter: params.filter,
      })
  );

  return {
    currencies: data?.data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    searchCurrencies: refetch,
    isFetching,
    isLoading,
  };
};

export default useSearchCurrencies;
