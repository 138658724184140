import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { getSupportedCountries } from "app/api/countries";

const useGetSupportedCountries = () => {
  const { isLoading, isFetching, error, refetch, data } = useQuery(
    ["supported-countries"],
    () => getSupportedCountries()
  );

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<any>) as string) : undefined,
    getCountries: refetch,
    countries: data?.data,
  };
};

export default useGetSupportedCountries;
