import Header from "app/styles/Header";
import Button from "./Button";
import tw from "twin.macro";

interface Props {
  error: any;
  resetError: () => void;
  dashboard?: boolean;
}
export default function ErrorWidget(props: Props) {
  const { resetError, dashboard } = props;

  return (
    <div
      css={[
        dashboard
          ? tw`w-full h-page`
          : tw`w-screen h-screen px-[16px] py-[24px]`,
      ]}
    >
      <div tw="h-full flex flex-col justify-center items-center gap-[16px]">
        <Header>Oops! Something went wrong</Header>

        <Button primary onClick={resetError}>
          Click here to reset!
        </Button>
      </div>
    </div>
  );
}
