import { useState } from "react";

import Page from "app/components/ui/Page";
import useGoBack from "app/hooks/useGoBack";
import { ConnectPaymentMethod } from "app/models/paymentMethod";

import AddUSDAccount from "./components/AddUSDAccount";
import SelectAccountType from "./components/SelectAccountType";
import SelectAccountMethod from "./components/SelectAccountMethod";
import AddNGNAccount from "./components/AddNGNAccount";

import TwoFactorAuthInput from "app/components/TwoFactorAuthInput";
import { useNavigate } from "react-router-dom";
import { GenericResponse } from "app/api/types";

const steps = ["currency", "account_type", "form", "2fa"] as const;

export type AccountType = "business" | "personal";

export type AccountMethod = {
  currency: "NGN" | "USD";
  country: string;
};

type ActiveStep = typeof steps[number];

export default function BankAccountsAdd() {
  const [activeStep, setActiveStep] = useState<ActiveStep>("currency");
  const [accountMethod, setAccountMethod] = useState<ConnectPaymentMethod>();
  const [accountType, setAccountType] = useState<ConnectPaymentMethod>();
  const [initializeResponse, setInitializeResponse] =
    useState<GenericResponse<{ token: string; message: string }>>();

  const previousPage = useGoBack();

  const navigate = useNavigate();

  const goBack = () => {
    const activeIndex = steps.indexOf(activeStep);
    if (activeStep === "form" && accountMethod!.currency === "NGN") {
      return setActiveStep("currency");
    }
    setActiveStep(steps[activeIndex - 1] as ActiveStep);
  };

  return (
    <Page
      breadCrumbs={{
        label: "Back",
        onAction: activeStep === "currency" ? previousPage : goBack,
      }}
    >
      {activeStep === "currency" && (
        <SelectAccountMethod
          onContinue={(account) => {
            setAccountMethod(account);
            setActiveStep(account.currency === "USD" ? "account_type" : "form");
          }}
        />
      )}

      {activeStep === "form" && accountMethod!.currency === "NGN" && (
        <AddNGNAccount
          accountMethod={accountMethod!}
          onSuccess={(
            d: GenericResponse<{ token: string; message: string }>
          ) => {
            setActiveStep("2fa");
            setInitializeResponse(d);
          }}
        />
      )}

      {activeStep === "account_type" && accountMethod!.currency === "USD" && (
        <SelectAccountType
          accountMethod={accountMethod!}
          onSelectType={(acc) => {
            setAccountType(acc);
            setActiveStep("form");
          }}
        />
      )}

      {activeStep === "form" &&
        accountMethod!.currency === "USD" &&
        accountType && (
          <AddUSDAccount
            accountType={accountType}
            onSuccess={(
              d: GenericResponse<{ token: string; message: string }>
            ) => {
              setActiveStep("2fa");
              setInitializeResponse(d);
            }}
          />
        )}

      {activeStep === "2fa" && (
        <TwoFactorAuthInput
          initializeResponse={initializeResponse}
          onSuccess={() => navigate("/profile/payment-methods/bank-accounts")}
        />
      )}
    </Page>
  );
}
