import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import { clearRecentSearch } from "app/api/accounts";

export default function useClearRecentSearches() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const handleError = (err: AxiosError<any>) => {
    const errorMessage = parseError(err) as string;
    toast("", errorMessage, "error");
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries(["recent-searches"]);
    toast("", "Recent search cleared", "success");
  };

  const { isLoading, mutate, error } = useMutation(() => clearRecentSearch(), {
    onError: handleError,
    onSuccess: handleSuccess,
  });

  return {
    isLoading,
    clearRecentSearch: mutate,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
  };
}
