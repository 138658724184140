import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { ReactComponent as LogoDark } from "app/assets/images/logo-dark.svg";
import { ReactComponent as LogoWhite } from "app/assets/images/logo-white.svg";
import { ReactComponent as LogoutIcon } from "app/assets/icons/logout.svg";

import media from "app/styles/media";

import { useAppTheme } from "app/providers/AppThemeProvider";
import { useProfile } from "app/providers/ProfileProvider";

import DashboardHeaderThemeButton from "./DashboardHeaderThemeButton";
import ProfileDropdown from "./ProfileDropdown";
import Button from "./Button";
import DashboardHeaderNotification from "../DashboardHeaderNotification";
import { gotoSignout } from "app/lib/logout";
import GlobalSearchInput from "../GlobalSearchInput";

export default function DashboardHeader() {
  const theme = useAppTheme();
  const profile = useProfile();

  const isUserPendingDeletion = profile?.status === "pending";

  const logo =
    theme === "dark" ? (
      <LogoWhite className="logo" />
    ) : (
      <LogoDark className="logo" />
    );

  return (
    <Container>
      <div className="nav">
        <Link aria-label="Explore" to="/">
          {logo}
        </Link>

        <div tw="tablet:hidden">
          <GlobalSearchInput />
        </div>
        <div
          className={clsx({ secondary: true, pending: isUserPendingDeletion })}
        >
          {!isUserPendingDeletion && <DashboardHeaderNotification />}
          <DashboardHeaderThemeButton />
          <ProfileDropdown />
          {isUserPendingDeletion && <LogOutButton />}
        </div>
      </div>
      <div tw="hidden tablet:block mt-[12px] mb-[12px] w-full">
        <GlobalSearchInput />
      </div>
    </Container>
  );
}

const LogOutButton = () => {
  return (
    <Button
      icon={<LogoutIcon />}
      onClick={gotoSignout}
      danger
      plain
      className="logout"
    >
      <span className="text">Log out</span>
    </Button>
  );
};

const Container = styled.nav`
  ${tw`w-full h-full px-[32px] my-auto tablet:(px-[16px] pt-[12px])`};

  .nav {
    ${tw`flex items-center justify-between gap-[8px]`}
  }

  .logo {
    height: 28px;
    width: 108.65388488769531px;

    ${media.mobile} {
      height: 24px;
      width: 93.13190460205078px;
    }
  }

  .secondary {
    ${tw`flex items-center gap-[32px]`};

    &.pending {
      ${tw`items-center`};
    }
  }

  .logout {
    svg {
      path {
        fill-opacity: 1;
      }
    }

    ${media.mobile} {
      .text {
        display: none;
      }
    }
  }
`;
