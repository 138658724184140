import tw, { styled } from "twin.macro";
import { useNavigate } from "react-router-dom";

import Header from "app/styles/Header";
import Text from "app/styles/Text";
import { ReactComponent as CaretRightIcon } from "app/assets/icons/caret-right.svg";
import { ReactComponent as AddIcon } from "app/assets/icons/add.svg";
import { ReactComponent as TrashIcon } from "app/assets/icons/trash-outline.svg";
import { ConnectPaymentAccount } from "app/models/paymentAccount";
import Card from "app/components/ui/Card";
import Page from "app/components/ui/Page";
import LoaderContainer from "app/components/ui/LoaderContainer";
import useGetPaymentAccounts from "app/hooks/useGetPaymentAccounts";
import Loader from "app/components/ui/Loader";
import NetworkLoader from "app/components/ui/NetworkLoader";
import useDeletePaymentAccount from "app/hooks/useDeletePaymentAccount";
import EmptyState from "./components/EmptyState";

const OtherPaymentAccounts = () => {
  const navigate = useNavigate();

  const { accounts, isLoading, error, refetch, isFetching } =
    useGetPaymentAccounts({ limit: 100, category: "other_account" });

  const otherAccounts = accounts?.data;

  return (
    <Page breadCrumbs={{ label: "Back", url: "/profile/payment-methods" }}>
      {isFetching && <NetworkLoader />}
      <LoaderContainer
        loading={isLoading}
        error={!!error}
        errorMessage={error}
        onRetry={refetch}
      >
        <Header tw="text-[2.8rem] font-bold mobile:(text-[2.4rem] font-semibold)">
          Other accounts
        </Header>

        {otherAccounts?.length !== 0 ? (
          <div tw="flex flex-col gap-8">
            <Card
              tw="mt-[24px] flex px-[20px] py-[16px] justify-between items-center"
              role="button"
              onClick={() => navigate("add")}
            >
              <div tw="flex items-center gap-[10px]">
                <button tw="flex items-center justify-center w-16 h-16 rounded-full bg-[#E6F2ED]">
                  <AddIcon tw="text-[#12A633]" />
                </button>
                <Text medium>Add a new account</Text>
              </div>

              <CaretRightIcon tw="text-black32 dark:text-white32" />
            </Card>

            <Card>
              <AccountsContainer>
                {otherAccounts?.map((account) => {
                  return <Account key={account.id} account={account} />;
                })}
              </AccountsContainer>
            </Card>
          </div>
        ) : (
          <EmptyState />
        )}
      </LoaderContainer>
    </Page>
  );
};

interface TileProps {
  account: ConnectPaymentAccount;
}

const Account = (props: TileProps) => {
  const { account } = props;

  const { deleteAccount, isLoading: isDeletingAccount } =
    useDeletePaymentAccount(account.id);

  return (
    <AccountTile>
      <div tw="flex items-center gap-[10px]">
        <img
          src={account.payment_method.logo}
          alt={account.payment_method.name}
          className="logo"
        />

        <div>
          <Text medium>{account.fields[0].value}</Text>

          <Text tw="text-black32 dark:text-white32">
            {account.payment_method.name}
          </Text>
        </div>
      </div>

      <button
        tw="text-black32 dark:text-white60 hover:text-[#920000]"
        onClick={() => deleteAccount()}
      >
        {isDeletingAccount ? <Loader size="18" /> : <TrashIcon width={18} />}
      </button>
    </AccountTile>
  );
};

const AccountTile = styled.div`
  ${tw`flex justify-between items-center`}

  .logo {
    ${tw`w-[40px] rounded-full`}
  }
`;

const AccountsContainer = styled.div`
  ${tw`flex flex-col gap-[16px] p-[20px] pb-[16px]`}

  > div:not(:last-child) {
    ${tw`border-b border-[#00000014] dark:border-[#FFFFFF14]  pb-[16px]`}
  }
`;

export default OtherPaymentAccounts;
