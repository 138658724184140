import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { verifyBankDetails } from "app/api/banks";
import { VerifyBankDetailsParams } from "app/api/banks/types";

const useBanks = () => {
  const {
    isLoading: verifyingAccountDetails,
    mutate: verifyAccount,
    data: verificationResponse,
    error: verificationError,
    reset: resetVerification,
  } = useMutation((d: VerifyBankDetailsParams) => verifyBankDetails(d));

  return {
    verifyingAccountDetails,
    verifyAccount,
    verificationResponse: verificationResponse?.data.data,
    verificationError: verificationError
      ? (parseError(verificationError as AxiosError<any>) as string)
      : undefined,
    resetVerification,
  };
};

export default useBanks;
