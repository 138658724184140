import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import parseError from "app/lib/parseError";
import { getVerificationStatus } from "app/api/identities";
import { useSetVerificationStatus } from "app/providers/VerificationStatusProvider";

const useGetVerificationStatus = (enabled = true, profileID: string) => {
  const setVerificationStatus = useSetVerificationStatus();

  const { isFetching, isLoading, error, data, refetch } = useQuery(
    ["verification-status", profileID],
    () => getVerificationStatus(profileID as string),
    {
      enabled,
      onSuccess(data) {
        setVerificationStatus(data.data.data);
      },
    }
  );

  return {
    isLoadingStatus: isLoading,
    isFetchingStatus: isFetching,
    statusError: error
      ? (parseError(error as AxiosError<any>) as string)
      : undefined,
    status: data?.data.data,
    getVerificationStatus: refetch,
  };
};

export default useGetVerificationStatus;
