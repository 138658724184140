import * as React from "react";
import tw, { styled } from "twin.macro";

import Text from "app/styles/Text";
import { isString } from "app/utils/helpers";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  className?: string;
}

function CheckBox(props: Props, ref: any) {
  const { label, className, ...rest } = props;

  return (
    <Label className={className}>
      <input ref={ref} type="checkbox" {...rest} />

      <div>
        <span>&#x2713;</span>
      </div>

      {!!label ? isString(label) ? <Text>{label}</Text> : label : null}
    </Label>
  );
}

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${tw`text-black60 dark:text-white60`}

  > div:first-of-type {
    border: 1px solid #b2b2b2;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;

    ${tw`dark:border-[#4D4D4D]`}

    span {
      font-size: 16px;
      font-weight: 400;
      display: none;
      color: #000;
      transform: rotate(8deg);
      ${tw`dark:text-white`}
    }
  }

  > p {
    ${tw`text-black dark:text-white`}
  }

  > input {
    display: none;

    :checked + div > span {
      display: block;
    }
  }
`;

export default React.forwardRef(CheckBox);
