import { useParams } from "react-router-dom";

import useGetPaymentAccount from "app/hooks/useGetPaymentAccount";
import LoaderContainer from "app/components/ui/LoaderContainer";
import NGNAccountDetails from "./components/NGNAccountDetails";
import USDAccountDetails from "./components/USDAccountDetails";

export default function BankAccountDetails() {
  const { id } = useParams();

  const { account, isLoading, error, refetch } = useGetPaymentAccount(id!);

  return (
    <LoaderContainer loading={isLoading} onRetry={refetch} error={!!error}>
      {account && (
        <>
          {account.payment_method.currency === "NGN" && (
            <NGNAccountDetails account={account} />
          )}

          {account.payment_method.currency === "USD" && (
            <USDAccountDetails account={account} />
          )}
        </>
      )}
    </LoaderContainer>
  );
}
