import styled from "@emotion/styled";
import tw from "twin.macro";
import { HTMLAttributeAnchorTarget, useMemo } from "react";

import { ReactComponent as LogoDeleteIcon } from "app/assets/icons/logo-delete.svg";

import Text from "app/styles/Text";
import Button from "app/components/ui/Button";
import useCountdownTimer from "app/hooks/useCountdownTimer";
import media from "app/styles/media";
import { useProfile } from "app/providers/ProfileProvider";
import { AUTH_APP_URL } from "app/constants/variables";

const AccountHalt = () => {
  return (
    <Container>
      <div>
        <LogoDeleteIcon className="delete-icon" />
      </div>
      <DeletionInfo />
      <Action>
        <Text medium subdued>
          If you would like to stop your account deletion process now, please
          click the button below
        </Text>
        <StopAction
          as={"a"}
          primary
          href={`${AUTH_APP_URL}?view=halt`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Stop account deletion
        </StopAction>
      </Action>
    </Container>
  );
};

const DeletionInfo = () => {
  const profile = useProfile();

  const difference = useMemo(() => {
    const deletionDate = new Date(
      profile?.pending_deletion_at || "2023/11/1"
    ).getTime();

    const nowDate = new Date().getTime();

    const difference = deletionDate - nowDate;
    return difference;
  }, [profile?.pending_deletion_at]);

  const { time } = useCountdownTimer(difference);
  const { days, hours, minutes, seconds } = time;

  return (
    <Content>
      <Text semiBold className="title">
        Account deletion request submitted
      </Text>
      <Text medium subdued>
        We are so sad to see you go. Your Busha account will be deleted within
        the next
      </Text>
      <TimeContainer>
        <TimeUnit unit="days" value={days} />
        <DeleteInfoColumn />
        <TimeUnit unit="hours" value={hours} />
        <DeleteInfoColumn />
        <TimeUnit unit="mins" value={minutes} />
        <DeleteInfoColumn />
        <TimeUnit unit="sec" value={seconds} />
      </TimeContainer>
    </Content>
  );
};

const DeleteInfoColumn = () => {
  return (
    <div className="column">
      <Text semiBold> : </Text>
    </div>
  );
};

interface TimeUnitProps {
  unit: string;
  value: number;
}

const TimeUnit = (props: TimeUnitProps) => {
  const { unit, value } = props;

  return (
    <TimeBox>
      <div className="data">
        <Text semiBold>
          {value < 10 && value >= 0 ? `0${value}` : value.toString()}
        </Text>
      </div>
      <div className="unit">
        <Text>{unit.toUpperCase()}</Text>
      </div>
    </TimeBox>
  );
};

const Container = styled.div`
  ${tw`flex flex-col items-center justify-center gap-[48px]`};
  ${tw`max-w-[440px] m-auto pt-[88.5px]`};
  ${tw`text-center`};

  .delete-icon {
    ${tw`w-[80px] h-[80px] object-contain`};
  }

  ${media.mobile} {
    ${tw`pt-[98px]`};
  }
`;

const Content = styled.div`
  ${tw`flex flex-col items-center justify-center gap-[24px]`};

  .title {
    font-size: 2.4em;
    line-height: 28.8px;
  }
`;

const Action = styled.div`
  ${tw`flex flex-col items-center justify-center gap-[16px]`};

  > button {
    ${media.mobile} {
      width: 100%;
    }
  }
`;

const TimeContainer = styled.div`
  ${tw`flex items-center gap-[8px]`};

  .column {
    ${tw`self-end`};
    ${tw`pb-[36px]`};

    p {
      font-size: 3.2em;
      line-height: 38.4px;
    }
  }
`;

const TimeBox = styled.div`
  ${tw`flex flex-col gap-[8px] text-center`};
  div {
    ${tw`flex justify-center items-center`};
  }
  .data {
    ${tw`rounded-[4px] p-[8px] w-[64px] h-[64px]`};
    ${media.mobile} {
      ${tw`max-w-[56px]`};
    }
    background: rgba(18, 18, 18, 0.12);

    box-shadow: 0px -32px 0px 0px rgba(18, 18, 18, 0.08) inset;

    p {
      font-size: 3.2em;
      line-height: 38.4px;

      ${media.mobile} {
        font-size: 2.8em;
        line-height: 33.6px;
        font-weight: 700;
      }
    }
  }

  .unit {
    ${tw`px-[8px] pb-[8px]`};
  }
`;

interface StopActionProps {
  to?: string;
  target?: HTMLAttributeAnchorTarget;
  rel?: string;
  href?: string;
}

const StopAction = styled(Button)<StopActionProps>``;

export default AccountHalt;
