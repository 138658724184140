import * as React from "react";

import { ActiveProfile } from "app/models/profile";
import storage from "app/lib/storage";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { ACTIVE_PROFILE_STORAGE_KEY } from "app/constants/variables";

export const ActiveProfileContext =
  React.createContext<ActiveProfile | undefined>(undefined);
ActiveProfileContext.displayName = "ActiveProfileContext";

export const SetActiveProfileContext = React.createContext<
  React.Dispatch<React.SetStateAction<ActiveProfile | undefined>>
>((p) => {});
SetActiveProfileContext.displayName = "SetActiveProfileContext";

export default function ActiveProfileProvider(props: React.PropsWithChildren) {
  const [activeProfile, setActiveProfile] = React.useState<
    ActiveProfile | undefined
  >(() => storage.get(ACTIVE_PROFILE_STORAGE_KEY) ?? undefined);

  useUpdatedEffect(() => {
    if (activeProfile) {
      storage.set(ACTIVE_PROFILE_STORAGE_KEY, activeProfile);
    }
  }, [activeProfile]);

  return (
    <ActiveProfileContext.Provider value={activeProfile}>
      <SetActiveProfileContext.Provider value={setActiveProfile}>
        {props.children}
      </SetActiveProfileContext.Provider>
    </ActiveProfileContext.Provider>
  );
}

export const useActiveProfile = () => {
  const activeProfile = React.useContext(ActiveProfileContext);

  return activeProfile;
};

export const useSetActiveProfile = () => {
  const setActiveProfile = React.useContext(SetActiveProfileContext);

  return setActiveProfile;
};
