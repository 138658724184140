import { useNavigate, useLocation } from "react-router-dom";
import { KYBStep } from "app/models/businessVerification";

interface UseKYBStepsReturn {
  steps: KYBStep[];
  currentStep: KYBStep;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isFirstKYBStep: boolean;
  isLastKYBStep: boolean;
  findFirstIncompleteStep: () => KYBStep | null;
}

const useKYBSteps = (initialSteps: KYBStep[]): UseKYBStepsReturn => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split("/").pop() || "";

  const findFirstIncompleteStep = (): KYBStep | null => {
    const findFirstIncomplete = (steps: KYBStep[]): KYBStep | null => {
      for (const step of steps) {
        if (step.status === "" || step.status === "pending") return step;

        if (step.children) {
          const incompleteChild = findFirstIncomplete(step.children);
          if (incompleteChild) return incompleteChild;
        }
      }
      return null;
    };

    return findFirstIncomplete(initialSteps);
  };

  const findCurrentStep = (
    steps: KYBStep[],
    path: string,
    parent: KYBStep | null = null
  ): { step: KYBStep; parent: KYBStep | null } | null => {
    for (const step of steps) {
      if (step.path === path && !step.children) {
        return { step, parent };
      }
      if (step.children) {
        const childPath =
          step.path === path ? step.path : `${step.path}/${path}`;
        const result = findCurrentStep(step.children, childPath, step);
        if (result) return result;
      }
    }
    return null;
  };

  const currentStepInfo = findCurrentStep(initialSteps, currentPath);
  const currentStep = currentStepInfo?.step;
  const parentStep = currentStepInfo?.parent;

  const isFirstKYBStep =
    !parentStep && initialSteps[0]?.path === currentStep?.path;
  const isLastKYBStep =
    !parentStep &&
    initialSteps[initialSteps.length - 1]?.path === currentStep?.path;

  const navigateToStep = (step: KYBStep) => {
    navigate(step.path);
  };

  const goToNextStep = () => {
    if (!currentStep) return;

    const firstIncompleteStep = findFirstIncompleteStep();
    if (!firstIncompleteStep) return;

    // Ensure navigation is limited to first incomplete step or previous steps
    const isValidNextStep = (step: KYBStep) => {
      const stepIndex = initialSteps.findIndex((s) => s.path === step.path);
      const firstIncompleteIndex = initialSteps.findIndex(
        (s) => s === firstIncompleteStep
      );
      return stepIndex <= firstIncompleteIndex;
    };

    if (currentStep.children && currentStep.children.length > 0) {
      const nextChild = currentStep.children[0];
      if (isValidNextStep(nextChild)) {
        navigateToStep(nextChild);
        return;
      }
    }

    if (parentStep) {
      const childIndex = parentStep.children!.findIndex(
        (child) => child.path === currentStep.path
      );
      if (childIndex < parentStep.children!.length - 1) {
        const nextSibling = parentStep.children![childIndex + 1];
        if (isValidNextStep(nextSibling)) {
          navigateToStep(nextSibling);
          return;
        }
      }
    }

    const stepIndex = initialSteps.findIndex(
      (step) => step.path === currentStep.path
    );
    if (stepIndex < initialSteps.length - 1) {
      const nextStep = initialSteps[stepIndex + 1];
      if (isValidNextStep(nextStep)) {
        navigateToStep(nextStep);
      }
    }
  };

  const goToPreviousStep = () => {
    if (!currentStep) return;

    const firstIncompleteStep = findFirstIncompleteStep();
    if (!firstIncompleteStep) return;

    const isValidPreviousStep = (step: KYBStep) => {
      const stepIndex = initialSteps.findIndex((s) => s.path === step.path);
      const firstIncompleteIndex = initialSteps.findIndex(
        (s) => s === firstIncompleteStep
      );
      return stepIndex < firstIncompleteIndex;
    };

    if (parentStep) {
      const childIndex = parentStep.children!.findIndex(
        (child) => child.path === currentStep.path
      );

      if (childIndex > 0) {
        const prevSibling = parentStep.children![childIndex - 1];
        if (isValidPreviousStep(prevSibling)) {
          navigateToStep(prevSibling);
          return;
        }
      }
    }

    const stepIndex = initialSteps.findIndex(
      (step) => step.path === currentStep.path
    );

    if (stepIndex > 0) {
      const previousStep = initialSteps[stepIndex - 1];

      const getLastChild = (step: KYBStep): KYBStep => {
        if (step.children && step.children.length > 0) {
          return getLastChild(step.children[step.children.length - 1]);
        }
        return step;
      };

      const lastChild = getLastChild(previousStep);
      if (isValidPreviousStep(lastChild)) {
        navigateToStep(lastChild);
      }
    }
  };

  return {
    steps: initialSteps,
    currentStep: currentStep || initialSteps[0],
    goToNextStep,
    goToPreviousStep,
    isFirstKYBStep,
    isLastKYBStep,
    findFirstIncompleteStep,
  };
};

export default useKYBSteps;
