import * as React from "react";

import { TOKEN_STORAGE_KEY } from "app/constants/variables";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import storage from "app/lib/storage";

export type TokenContextType = {
  access_token?: string;
  refresh_token?: string;
};

const TokenContext =
  React.createContext<TokenContextType | undefined>(undefined);
TokenContext.displayName = "TokenContext";

const TokenDispatch = React.createContext<
  React.Dispatch<React.SetStateAction<TokenContextType | undefined>>
>(() => {});
TokenDispatch.displayName = "TokenDispatch";

export default function TokenProvider(props: React.PropsWithChildren<any>) {
  const [token, setToken] = React.useState<TokenContextType | undefined>(
    () => storage.get(TOKEN_STORAGE_KEY) ?? undefined
  );

  useUpdatedEffect(() => {
    if (token) {
      storage.set(TOKEN_STORAGE_KEY, token);
    }
  }, [token]);

  return (
    <TokenDispatch.Provider value={setToken}>
      <TokenContext.Provider value={token}>
        {props.children}
      </TokenContext.Provider>
    </TokenDispatch.Provider>
  );
}

export function useToken() {
  return React.useContext(TokenContext);
}

export function useSetToken() {
  return React.useContext(TokenDispatch);
}
