import FingerprintJS from "@fingerprintjs/fingerprintjs";
import UAParser from "ua-parser-js";

export async function getDeviceId() {
  const fpPromise = FingerprintJS.load();
  const fp = await fpPromise;

  const result = await fp.get();

  return result.visitorId;
}

export function getDeviceName() {
  const parser = new UAParser();
  const result = parser.getResult();

  const osAndBrowsername = result.os.name + " " + result.browser.name;
  return osAndBrowsername;
}
