/** Todo
 * Add shimmer effect to the Global Search Feature ☑️
 * integrate TopAssets components inside the Global Search Feature
 */

import tw, { styled } from "twin.macro";
import Text from "app/styles/Text";

export default function RecentSearchShimmer() {
  const arr = new Array(4).fill(0);
  return (
    <div tw="w-full mb-[32px]">
      <div tw="mb-[16px]">
        <Text medium semiBold>
          Recent
        </Text>
      </div>
      <RecentSearchContainer>
        {arr.map((index) => (
          <div
            key={index}
            tw="flex gap-[8px] p-[6px_8px] items-center cursor-pointer bg-[#F9F9F9] dark:bg-[#0D0D0D] rounded-full"
          >
            <div className="img"></div>
            <div className="text"></div>
          </div>
        ))}
      </RecentSearchContainer>
    </div>
  );
}

export function AssetsShimmer() {
  const arr = new Array(4).fill(0);
  return (
    <div>
      {arr.map((index) => (
        <AssetsShimmerContainer key={index}>
          <ListItem className="item">
            <div className="coin">
              <div className="img"></div>
              <div tw="flex flex-col gap-[4px]">
                <div className="coin--currency"></div>
                <div className="coin--name"></div>
              </div>
            </div>
            <div className="coin--value">
              <div className="coin--rate"></div>
              <div className="coin--currency"></div>
            </div>
          </ListItem>
        </AssetsShimmerContainer>
      ))}
    </div>
  );
}

const AssetsShimmerContainer = styled.div`
  .item {
    border-bottom: 1px solid rgba(91, 97, 110, 0.2);
  }

  &:last-of-type {
    .item {
      border: none;
    }
  }
`;

const ListItem = styled.div`
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .coin {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .img {
      ${tw`animate-pulse h-[40px] w-[40px] rounded-full bg-[#E5E5E5] dark:bg-[#1A1A1A]`}
    }

    &--name {
      ${tw`animate-pulse h-[22px] w-[80px] rounded-[4px] bg-[#E5E5E5] dark:bg-[#1A1A1A]`}
    }

    &--currency {
      ${tw`animate-pulse h-[22px] w-[40px] rounded-[4px] bg-[#E5E5E5] dark:bg-[#1A1A1A]`}
    }

    &--rate {
      ${tw`animate-pulse h-[22px] w-[120px] rounded-[4px] bg-[#E5E5E5] dark:bg-[#1A1A1A]`}
    }

    &--value {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-end;
    }
  }
`;

const RecentSearchContainer = styled.div`
  display: flex;
  overflow: auto;
  gap: 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  .img {
    ${tw`animate-pulse h-[32px] w-[32px] rounded-full bg-[#E5E5E5] dark:bg-[#1A1A1A]`}
  }

  .text {
    ${tw`animate-pulse h-[22px] w-[40px] rounded-[4px] bg-[#E5E5E5] dark:bg-[#1A1A1A]`}
  }
`;
