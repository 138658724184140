import { useState } from "react";

import Page from "app/components/ui/Page";
import SelectCurrency from "./components/SelectCurrency";
import SelectProvider from "./components/SelectProvider";
import AddAccountForm from "./components/AddAccountForm";
import useGoBack from "app/hooks/useGoBack";
import { ConnectPaymentMethod } from "app/models/paymentMethod";
import TwoFactorAuthInput from "app/components/TwoFactorAuthInput";
import { useNavigate } from "react-router-dom";
import { GenericResponse } from "app/api/types";

const steps = ["currency", "provider", "form", "2fa"] as const;
type ActiveStepType = typeof steps[number];

export default function OtherPaymentAccountsAdd() {
  const previousPage = useGoBack();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState<ActiveStepType>("currency");

  const [currency, setCurrency] = useState("");
  const [method, setMethod] = useState<ConnectPaymentMethod | null>(null);
  const [initializeResponse, setInitializeResponse] =
    useState<GenericResponse<{ token: string; message: string }>>();

  const onContinue = (p: ActiveStepType) => setActiveStep(p);

  const goBack = () => {
    const activeIndex = steps.indexOf(activeStep);
    setActiveStep(steps[activeIndex - 1] as ActiveStepType);
  };

  return (
    <Page
      breadCrumbs={{
        label: "Back",
        onAction: activeStep === "currency" ? previousPage : goBack,
      }}
    >
      {activeStep === "currency" && (
        <SelectCurrency
          onContinue={(c: string) => {
            onContinue("provider");
            setCurrency(c);
          }}
        />
      )}

      {activeStep === "provider" && (
        <SelectProvider
          currency={currency}
          onContinue={(m: ConnectPaymentMethod) => {
            onContinue("form");
            setMethod(m);
          }}
        />
      )}

      {activeStep === "form" && (
        <AddAccountForm
          method={method as ConnectPaymentMethod}
          onSuccess={(
            d: GenericResponse<{ token: string; message: string }>
          ) => {
            onContinue("2fa");
            setInitializeResponse(d);
          }}
        />
      )}

      {activeStep === "2fa" && (
        <TwoFactorAuthInput
          initializeResponse={initializeResponse}
          onSuccess={() => navigate("/profile/payment-methods/other-accounts")}
        />
      )}
    </Page>
  );
}
