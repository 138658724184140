import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";

import { formatMoneyNoCurrency } from "app/lib/money";
import Paths from "app/utils/paths";
import Text from "app/styles/Text";
import SubHeader from "app/styles/SubHeader";
import { useProfile } from "app/providers/ProfileProvider";
import { Combobox } from "@headlessui/react";
import { Currency } from "app/models/currency";

interface Props {
  accounts: (Currency & { balance: number })[];
}

export default function Assets(props: Props) {
  const { accounts } = props;
  const profile = useProfile();

  return (
    <div>
      <SubHeader tw="mb-[16px]">Assets</SubHeader>

      {accounts.length > 0 ? (
        <div>
          {accounts.map((account) => (
            <Wallet
              key={account.name}
              account={account}
              displayCurrency={profile?.display_currency || "USD"}
            />
          ))}
        </div>
      ) : (
        <EmptyState />
      )}
    </div>
  );
}

interface WalletProps {
  account: Currency & { balance: number };
  displayCurrency: string;
}

function Wallet(props: WalletProps) {
  const { account, displayCurrency } = props;

  const fiatBalance =
    account.type !== "fiat"
      ? `${formatMoneyNoCurrency(
          Number(account.balance || "0"),
          "fiat"
        )} ${displayCurrency}`
      : null;

  return (
    <WalletLink to={`/spot/${account.code}`} state={{ from_search: true }}>
      <Combobox.Option value={account.name} tw="list-none">
        <ListItem className="item">
          <div className="coin">
            <img src={`${Paths.coinSVG(account.code)}`} alt={account.name} />

            <div>
              <Text medium> {account.code} </Text>
              <Text className="coin--name"> {account.name} </Text>
            </div>
          </div>

          <div className="coin--value">
            <Text medium>
              {formatMoneyNoCurrency(
                Number.parseFloat(String(account.balance) || "0"),
                account.type
              )}{" "}
              {account.code}
            </Text>

            {fiatBalance && <Text className="coin--name">{fiatBalance}</Text>}
          </div>
        </ListItem>
      </Combobox.Option>
    </WalletLink>
  );
}

const EmptyState = () => {
  return (
    <div tw="flex justify-center items-center">
      <Text> No wallet matches the filter.</Text>
    </div>
  );
};

const WalletLink = styled(Link)`
  .item {
    border-bottom: 1px solid rgba(91, 97, 110, 0.2);
  }

  &:last-of-type {
    .item {
      border: none;
      padding-bottom: 0px;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const ListItem = styled.div`
  display: flex;
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .coin {
    display: flex;
    align-items: center;
    column-gap: 10px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 32px;
    }

    &--name {
      ${tw`text-black32 dark:text-white32`}
    }

    &--value {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &--rate {
      &:last-of-type {
        ${tw`text-black60 dark:text-white60`}
      }
    }
  }
`;
