import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { getBooks } from "app/api/books";

import parseError from "app/lib/parseError";
import { BOOKS_REQUEST_QUERY } from "app/constants/commonQueries";
import { parseBook } from "app/utils/parser";

const useGetBooks = () => {
  const { isFetching, isLoading, error, refetch, data, status } = useQuery(
    [BOOKS_REQUEST_QUERY],
    getBooks
  );

  return {
    isFetching,
    isLoading,
    error: error ? (parseError(error as AxiosError<any>) as string) : undefined,
    getBooks: refetch,
    books: data?.data ? data.data.map(parseBook) : undefined,
    status,
  };
};

export default useGetBooks;
