import * as React from "react";

import { VerificationStatusResponse } from "app/api/identities/types";

export const VerificationStatusContext =
  React.createContext<VerificationStatusResponse | undefined>(undefined);
VerificationStatusContext.displayName = "VerificationStatusContext";

export const SetVerificationStatus = React.createContext<
  React.Dispatch<React.SetStateAction<VerificationStatusResponse | undefined>>
>((p) => {});
SetVerificationStatus.displayName = "SetVerificationStatus";

export default function VerificationStatusProvider(
  props: React.PropsWithChildren
) {
  const [verificationStatus, setVerificationStatus] =
    React.useState<VerificationStatusResponse | undefined>(undefined);

  return (
    <VerificationStatusContext.Provider value={verificationStatus}>
      <SetVerificationStatus.Provider value={setVerificationStatus}>
        {props.children}
      </SetVerificationStatus.Provider>
    </VerificationStatusContext.Provider>
  );
}

export const useVerificationStatus = () => {
  const verificationStatus = React.useContext(VerificationStatusContext);

  return verificationStatus;
};

export const useSetVerificationStatus = () => {
  const setVerificationStatus = React.useContext(SetVerificationStatus);

  return setVerificationStatus;
};
