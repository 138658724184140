import Page from "app/components/ui/Page";
import useGoBack from "app/hooks/useGoBack";

import PaymentAccounts from "./components/PaymentAccounts";

export default function BankAccounts() {
  const goBack = useGoBack();

  return (
    <Page
      title="Bank accounts"
      breadCrumbs={{
        label: "Back",
        onAction: goBack,
      }}
    >
      <PaymentAccounts />
    </Page>
  );
}
