import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import parseError from "app/lib/parseError";
import { getProfiles } from "app/api/profile";
import {
  useActiveProfile,
  useSetActiveProfile,
} from "app/providers/ActiveProfileProvider";
import { PROFILES_QUERY } from "app/constants/commonQueries";
import { parseToken } from "app/lib/encryption";

const useGetProfiles = (enabled = true) => {
  const activeProfile = useActiveProfile();

  const setActiveProfile = useSetActiveProfile();

  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    [PROFILES_QUERY],
    getProfiles,
    {
      enabled,
      // 5 minutes
      staleTime: 1000 * 60 * 5,
    }
  );

  useEffect(() => {
    if (activeProfile) {
      const token = parseToken();

      if ((token?.uid as string) === activeProfile.user_id) return;
    }

    if (!data) return;

    const profiles = data.data.data;

    // first personal profile
    const personalProfile = profiles.find((p) => p.profile_type === "personal");

    setActiveProfile(personalProfile ?? profiles[0]);
  }, [activeProfile, data, setActiveProfile]);

  return {
    profiles: data?.data.data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getProfiles: refetch,
    isFetching,
    isLoading,
  };
};

export default useGetProfiles;
