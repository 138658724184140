import tw, { styled } from "twin.macro";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";
import useClearRecentSearches from "app/hooks/useClearRecentSearches";
import Paths from "app/utils/paths";
import { Link } from "react-router-dom";
import { Combobox } from "@headlessui/react";
import RecentSearchShimmer from "./Shimmer";
import useGetRecentSearches from "app/hooks/useGetRecentSearches";

export default function RecentSearch() {
  const { clearRecentSearch, isLoading: isClearingRecentSearch } =
    useClearRecentSearches();
  const { data, isFetching } = useGetRecentSearches();
  const recentSearchData = data?.data ?? [];

  return (
    <>
      {isFetching ? (
        <RecentSearchShimmer />
      ) : recentSearchData.length > 0 ? (
        <Container>
          <div tw="flex justify-between items-baseline mb-[16px]">
            <Text medium semiBold>
              Recent
            </Text>
            <Button
              tw="text-primary70 p-0"
              loading={isClearingRecentSearch}
              onClick={() => clearRecentSearch()}
            >
              Clear
            </Button>
          </div>
          <div className="row-item">
            {recentSearchData?.map((data) => (
              <ItemContainer key={data} to={`/spot/${data}`}>
                <Combobox.Option value={data} tw="list-none	">
                  <div tw="flex gap-[8px] p-[6px_8px] items-center rounded-full cursor-pointer border border-[#E5E5E5] bg-[#F9F9F9] dark:border-[#1A1A1A] dark:bg-[#0D0D0D]">
                    <img src={`${Paths.coinSVG(data)}`} alt="Coins" />
                    <div>
                      <Text>{data}</Text>
                    </div>
                  </div>
                </Combobox.Option>
              </ItemContainer>
            ))}
          </div>
        </Container>
      ) : null}
    </>
  );
}

const Container = styled.div`
  ${tw`w-full mb-[32px]`}

  .row-item {
    display: flex;
    overflow: auto;
    gap: 8px;

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      ${tw`h-[32px] w-[32px] rounded-full object-cover`}
    }
  }
`;

const ItemContainer = styled(Link)`
  ${tw`block min-w-[100px]`}
`;
