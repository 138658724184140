import crypto from "crypto";
import storage from "./storage";
import { TOKEN_STORAGE_KEY } from "app/constants/variables";

export const generateHMAC = (userID: string, secretKey: string) => {
  const hash = crypto
    .createHmac("sha256", secretKey)
    .update(userID)
    .digest("hex");
  return hash;
};

type ParsedToken = {
  exp: string;
  iat: string;
  ipaddrs: number;
  uid: string;
};

export const parseToken = () => {
  const token: { access_token: string } = storage.get(TOKEN_STORAGE_KEY);

  if (!token) {
    return null;
  }

  var base64Url = token.access_token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload) as ParsedToken;
};

export async function getUserDetailsFromToken(access_token: string) {
  try {
    let base64Url = access_token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = JSON.parse(atob(base64));

    const userId = jsonPayload.uid;
    const userEmail = jsonPayload.email;

    return { userId, userEmail };
  } catch (error) {
    console.error("Error decoding token:", error);
  }
}
