import { ACTIVE_PROFILE_STORAGE_KEY, AUTH_URL } from "app/constants/variables";
import {
  IdentityComment,
  IdentityPayload,
  MissingIdentity,
} from "app/models/identities";

import request from "../request";
import { BaseResponse, GenericResponse } from "../types";
import {
  CreateIdentityPayload,
  GetUploadedDocumentsResponse,
  RetrieveFlowResponse,
  StartOnboardingPayload,
  StartOnboardingResponse,
  VerificationStatusResponse,
} from "./types";
import storage from "app/lib/storage";
import isEmpty from "app/lib/isEmpty";

const defaultOption = { baseURL: AUTH_URL };

const getRequestHeaders = () => {
  const { id } = storage.get(ACTIVE_PROFILE_STORAGE_KEY) ?? {};

  if (isEmpty(id)) return {};
  return {
    "X-BU-PROFILE-ID": id,
  };
};

export const getUploadedDocuments = () => {
  return request.get<GenericResponse<GetUploadedDocumentsResponse>>(
    "/identities/documents",
    defaultOption
  );
};

export const createIdentity = (payload: CreateIdentityPayload) => {
  return request.post<BaseResponse>("/identities", payload, defaultOption);
};

export const getMissingIdentities = () => {
  return request.get<GenericResponse<MissingIdentity[]>>(
    "/identities/missing",
    defaultOption
  );
};

export const checkVerificationStatus = () => {
  return request.get<GenericResponse<{ has_pending: boolean }>>(
    "/identities/exists",
    defaultOption
  );
};

export const retrieveFlow = () => {
  return request.get<GenericResponse<RetrieveFlowResponse>>(
    "/identities/flow",
    defaultOption
  );
};

export const getVerificationStatus = async (profileID?: string) => {
  const headers = {
    "X-BU-PROFILE-ID": profileID,
  };
  return await request.get<GenericResponse<VerificationStatusResponse>>(
    "/identities/status",
    {
      ...defaultOption,
      headers,
    }
  );
};

export const startOnboarding = (payload: StartOnboardingPayload) => {
  return request.post<GenericResponse<StartOnboardingResponse>>(
    "/identities/start",
    payload,
    defaultOption
  );
};

export const getIdentitiesEvents = () => {
  return request.get<GenericResponse<IdentityComment[]>>(
    "/identities/business/events",
    {
      ...defaultOption,
      headers: getRequestHeaders(),
    }
  );
};

export const addIdentitiesEvents = (payload: IdentityPayload) => {
  return request.post<GenericResponse<IdentityPayload>>(
    "/identities/business/comment",
    payload,
    {
      ...defaultOption,
      headers: getRequestHeaders(),
    }
  );
};
